<template>
  <div/>
</template>

<script>

import environment from "@/environment";

export default {
  name: "ProfessionalAvailability",
  mounted() {
    this.$nextTick(() => {
      window.location.href = `${environment.portalBoard}/schedule`
    });
  }
};
</script>
