import environment from '@/environment';

const EHEALTH_PATH = 'api/ehealth';
const NO_AUTH_PATH = 'api/noauth';
const PATIENT_PATH = 'api/patient';

export const LANDING_COMMON_PATH = 'website';
export const GOOGLE_AUTHENTICATOR_PATH = 'download-google-authenticator';

export const nameEnv = environment.name;
export const apiDomain = environment.apiUrl;
export const api2Domain = environment.api2Url;
export const appVideoUrl = environment.appVideoUrl;
export const displayNameEnv = environment.displayName;
export const defaultCountry = environment?.forms?.defaultCountry ?? null;

// login
export const loginUrl = apiDomain + 'oauth/token';
export const resetPasswordUrl = apiDomain + NO_AUTH_PATH + '/resetPassword';
export const activarCuentaUrl = apiDomain + NO_AUTH_PATH + '/activarCuenta';
export const activateProfessionalAsPatient = apiDomain + NO_AUTH_PATH + '/activateProfessionalAsPatient';
export const acceptLinkPatientProfessionalUrl = apiDomain + NO_AUTH_PATH + '/acceptLinkPatientProfessional';
export const accesoIconnectaUrl = apiDomain + NO_AUTH_PATH + '/accesoIconnectaUrl';
export const logoutUrl = apiDomain + EHEALTH_PATH + '/logout';
export const closeSession = apiDomain + 'api/ehealth/closeSession';
export const loginStep2 = apiDomain + 'api/loginStep2';
export const getDataBlockchain = apiDomain + NO_AUTH_PATH + '/blockchain/getData';
export const sendActivateAccountEmail = apiDomain + EHEALTH_PATH + '/sendActivateAccountEmail';

// signUp
export const signUpUrl = apiDomain + NO_AUTH_PATH + '/signUp';

// symptom checker - infermedica - triage
export const createTriagePdfUrl = apiDomain + NO_AUTH_PATH + '/v1/triage';
export const verifyTriageCodeUrl = apiDomain + NO_AUTH_PATH + '/v1/triage/verifyTriageCode';
export const generateTriageCodeUrl = apiDomain + EHEALTH_PATH + '/v1/triage/createTriageCode';

// comun
export const userUrl = apiDomain + 'api/user';
export const dashBoardUrl = apiDomain + EHEALTH_PATH + '/dashboard';
export const getNotificacionesUrl = apiDomain + EHEALTH_PATH + '/notificaciones';
export const getFilesHelpUrl = apiDomain + EHEALTH_PATH + '/help';
export const getFAQUrl = apiDomain + NO_AUTH_PATH + '/faq';
export const getListadoPacientesUrl = apiDomain + EHEALTH_PATH + '/buscadorPacientes';
export const enviarPreguntaUrl = apiDomain + EHEALTH_PATH + '/enviarPregunta';
export const comprobarVideosAyuda = apiDomain + EHEALTH_PATH + '/comprobarVideosAyuda';
export const activarSuscripcionUrl = apiDomain + EHEALTH_PATH + '/activarSuscripcion';
export const saveStepToStepUrl = apiDomain + EHEALTH_PATH + '/saveStepToStep';
export const saveStepClinicalUrl = apiDomain + EHEALTH_PATH + '/saveStepClinical';
export const saveStepBillingUrl = apiDomain + EHEALTH_PATH + '/saveStepBilling';
export const realizarReservaUrl = apiDomain + EHEALTH_PATH + '/realizarReserva';
export const saveSpecialtiesUrl = apiDomain + EHEALTH_PATH + '/guardarEspecialidades';
export const makeBookingUrl = apiDomain + NO_AUTH_PATH + '/makeBooking';
export const asignarConsultaUrl = apiDomain + EHEALTH_PATH + '/asignarConsulta';
export const cancelarChequeoUrl = apiDomain + EHEALTH_PATH + '/chequeos/cancelar';
export const getAlowPrescribeSpecialties = apiDomain + NO_AUTH_PATH + '/getAlowPrescribeSpecialties';
export const sendPremiumInformationEmail = apiDomain + EHEALTH_PATH + '/moreInformation';
export const professionalHasSign = apiDomain + EHEALTH_PATH + '/settings/hasSign';

// medical revision
export const reconocimientosUrl = apiDomain + EHEALTH_PATH + '/reconocimientos';
export const saveStatusReco = apiDomain + EHEALTH_PATH + '/actualizarEstadoReconocimiento';
export const saveNewReco = apiDomain + EHEALTH_PATH + '/createNewRevision';
export const getRevisionFileUrl = apiDomain + EHEALTH_PATH + '/pacientes/getRevision';

//chequeos
export const getChequeoFileUrl = apiDomain + EHEALTH_PATH + '/pacientes/getChequeo';

// perfil
export const switchRoleUrl = apiDomain + EHEALTH_PATH + '/switchRole';
export const getProfileUrl = apiDomain + EHEALTH_PATH + '/profile/get';
export const saveProfileUrl = apiDomain + EHEALTH_PATH + '/profile/save';
export const acceptConditionsUrl = apiDomain + EHEALTH_PATH + '/profile/condiciones';
export const getMiWebUrl = apiDomain + EHEALTH_PATH + '/profile/miweb';
export const checkPassword = apiDomain + EHEALTH_PATH + '/profile/checkPassword';
export const changePasswordUrl = apiDomain + EHEALTH_PATH + '/password';
export const enableTwoFaUrl = apiDomain + EHEALTH_PATH + '/profile/2fa/enable';
export const disableTwoFaUrl = apiDomain + EHEALTH_PATH + '/profile/2fa/disable';
export const twoFaDownloadAppSms = apiDomain + EHEALTH_PATH + '/profile/2fa/sms/download';

// paciente
export const pacientesUrl = apiDomain + EHEALTH_PATH + '/pacientes';
export const newPatientsUrl = apiDomain + EHEALTH_PATH + '/patients';
export const getPacienteUrl = apiDomain + EHEALTH_PATH + '/pacientes/get';
export const createPacienteUrl = apiDomain + EHEALTH_PATH + '/pacientes/create';
export const deletePacienteUrl = apiDomain + EHEALTH_PATH + '/pacientes/delete';
export const editPatientPersonalDataUrl = apiDomain + EHEALTH_PATH + '/pacientes/editPersonalData';
export const editPacienteUrl = apiDomain + EHEALTH_PATH + '/pacientes/editPersonales';
export const evolucionPacienteUrl = apiDomain + EHEALTH_PATH + '/pacientes/evolucion';
export const uploadDocumentUrl = apiDomain + EHEALTH_PATH + '/documents';
export const getDocumentoFileUrl = apiDomain + EHEALTH_PATH + '/pacientes/getDocumento';
export const showDocumentUrl = apiDomain + EHEALTH_PATH + '/patient/showDocument';
export const generateClinicalReportUrl = apiDomain + EHEALTH_PATH + '/patient/clinicalReport/history';
export const cuidarPacienteUrl = apiDomain + EHEALTH_PATH + '/pacientes/cuidarPaciente';
export const preanestesiaPacienteUrl = apiDomain + EHEALTH_PATH + '/pacientes/preanestesia';
export const showDeletePatient = apiDomain + EHEALTH_PATH + '/pacientes/checkIfShowDeleteButton';
export const patientsSearchUrl = apiDomain + EHEALTH_PATH + '/patients/search';
export const getPatientDetailUrl = apiDomain + EHEALTH_PATH + '/patients/';
export const savePatientObservation = apiDomain + EHEALTH_PATH + '/patient/saveObservation';
export const getPatientObservation = apiDomain + EHEALTH_PATH + '/patient/getObservation';
export const deletePatientObservation = apiDomain + EHEALTH_PATH + '/patient/deleteObservation';
export const editObservation = apiDomain + EHEALTH_PATH + '/patient/editObservation';
export const filesUrl = apiDomain + EHEALTH_PATH + '/files';

// mensajeria
export const allMensajeriaUrl = apiDomain + EHEALTH_PATH + '/mensajeria';
export const sendMensajeUrl = apiDomain + EHEALTH_PATH + '/mensajeria/send';
export const cerrarMensajeUrl = apiDomain + EHEALTH_PATH + '/mensajeria/cerrar';
export const cancelarMensajeUrl = apiDomain + EHEALTH_PATH + '/mensaje/cancelar';
export const modificarFileUrl = apiDomain + EHEALTH_PATH + '/mensajeria/modificarFile';
export const mensajeLeidoUrl = apiDomain + EHEALTH_PATH + '/mensajeria/leido';
export const eliminarFileUrl = apiDomain + EHEALTH_PATH + '/mensajeria/eliminarFile';
export const modificarFechaRevMed = apiDomain + EHEALTH_PATH + '/revmedica/modificarFecha';

// videoconsultas
export const allVideoconsultasUrl = apiDomain + EHEALTH_PATH + '/videoconsulta/index';
export const listVideoconsultationsUrl = apiDomain + EHEALTH_PATH + '/videoconsultations/all';
export const downloadVideoconsultationUrl = apiDomain + EHEALTH_PATH + '/videoconsultations/download';
export const guardarVideoconsultaUrl = apiDomain + EHEALTH_PATH + '/videoconsulta/guardar';
export const cancelarVideoconsultaUrl = apiDomain + EHEALTH_PATH + '/videoconsulta/cancelar';
export const confirmarVideoconsultaUrl = apiDomain + EHEALTH_PATH + '/videoconsulta/confirmar';
export const getAgendaEspUrl = apiDomain + EHEALTH_PATH + '/videoconsulta/getAgendaEsp';

// calendario
export const calendarioEspecialidadIndex = apiDomain + EHEALTH_PATH + '/calendarioEspecialidad/index';
export const getCalendarioEspecialidad = apiDomain + EHEALTH_PATH + '/calendarioEspecialidad/get';
export const calendarProfessionalIndex = apiDomain + EHEALTH_PATH + '/calendarProfessional/index';
export const getCalendarProfessional = apiDomain + EHEALTH_PATH + '/calendarProfessional/get';

//packs contratados
export const packsContratadosUrl = apiDomain + EHEALTH_PATH + '/packs/contratados';
export const completarPackUrl = apiDomain + EHEALTH_PATH + '/packs/completar';

//Suscripciones contratadas
export const suscripcionesContratadasUrl = apiDomain + EHEALTH_PATH + '/suscripciones/index';
export const cancelarSuscripcionUrl = apiDomain + EHEALTH_PATH + '/suscripciones/cancelar';

// recetas
export const nuevaRecetaUrl = apiDomain + EHEALTH_PATH + '/receta/nueva';
export const getListadoMedicamentosUrl = apiDomain + EHEALTH_PATH + '/receta/medicamentos';
export const generarVistaPreviaRecetaUrl = apiDomain + EHEALTH_PATH + '/receta/generarVistaPrevia';
export const getRecetaFileUrl = apiDomain + EHEALTH_PATH + '/receta/getFile';
export const showPrescriptionUrl = apiDomain + EHEALTH_PATH + '/prescription/show';
export const searchMedicinesUrl = apiDomain + EHEALTH_PATH + '/medicines/search';
export const searchActiveIngredientsUrl = apiDomain + EHEALTH_PATH + '/activeIngredients/search';
export const getMedicinesAmount = apiDomain + EHEALTH_PATH + '/medicines/packageAmount';
export const getPharmaceuticalFormsUrl = apiDomain + EHEALTH_PATH + '/pharmaceutical-forms';
export const getAdministrationWaysUrl = apiDomain + EHEALTH_PATH + '/administration-ways';

//reportes
export const reportesUrl = apiDomain + EHEALTH_PATH + '/reportes';
export const reportesMedyolaUrl = apiDomain + EHEALTH_PATH + '/reportesMedyola';
export const reportesDingdocUrl = apiDomain + EHEALTH_PATH + '/reportesDingdoc';
export const sumPontgrupUrl = apiDomain + EHEALTH_PATH + '/sumPontgrup';

//facturacion
export const facturacionUrl = apiDomain + EHEALTH_PATH + '/facturacion';
export const transaccionUrl = apiDomain + EHEALTH_PATH + '/transaccion';

// settings
export const talonarioUrl = apiDomain + EHEALTH_PATH + '/ajustes/talonario';
export const firmaUrl = apiDomain + EHEALTH_PATH + '/ajustes/firma';
export const scheduleUrl = apiDomain + EHEALTH_PATH + '/settings/schedule';
export const especialidadUrl = apiDomain + EHEALTH_PATH + '/ajustes/especialidad';
export const dayOffUrl = apiDomain + EHEALTH_PATH + '/settings/dayOff';
export const getNoDisponibleUrl = apiDomain + EHEALTH_PATH + '/ajustes/getNoDisponibles';
export const tiposVideoconsulta = apiDomain + EHEALTH_PATH + '/ajustes/videoconsulta';
export const suscripcionUrl = apiDomain + EHEALTH_PATH + '/ajustes/suscripcion';
export const packsUrl = apiDomain + EHEALTH_PATH + '/ajustes/packs';
export const cuponesUrl = apiDomain + EHEALTH_PATH + '/ajustes/cupones';
export const firmaLinkUrl = apiDomain + NO_AUTH_PATH + '/firmalink';
export const getReceptionistsUrl = apiDomain + EHEALTH_PATH + '/ajustes/receptionist/index';
export const createReceptionistUrl = apiDomain + EHEALTH_PATH + '/ajustes/receptionist/create';
export const editReceptionistUrl = apiDomain + EHEALTH_PATH + '/ajustes/receptionist/edit';
export const deleteReceptionistUrl = apiDomain + EHEALTH_PATH + '/ajustes/receptionist/delete';
export const getCustomValuesUrl = apiDomain + EHEALTH_PATH + '/ajustes/customValues/index';
export const editCustomValuesUrl = apiDomain + EHEALTH_PATH + '/ajustes/customValues/edit';
export const getSettingServiceUrl = apiDomain + EHEALTH_PATH + '/settings/services';
export const listServicesUrl = apiDomain + EHEALTH_PATH + '/services';
export const saveSettingServiceUrl = apiDomain + EHEALTH_PATH + '/settings/services/save';
export const saveMessageServiceUrl = apiDomain + EHEALTH_PATH + '/settings/message/save';

export const assistantUrl = apiDomain + 'api/entity-management/assistants';

// Agenda
export const indexDiasDisponibles = apiDomain + EHEALTH_PATH + '/ajustes/diasDisponibles/index';
export const crearDisponible = apiDomain + EHEALTH_PATH + '/ajustes/diasDisponibles/create';
export const editarDisponible = apiDomain + EHEALTH_PATH + '/ajustes/diasDisponibles/edit';
export const eliminarDisponible = apiDomain + EHEALTH_PATH + '/ajustes/diasDisponibles/delete';

//professionals
export const professionalsPath = apiDomain + EHEALTH_PATH + '/professionals';

// usuarios
export const usuariosUrl = apiDomain + EHEALTH_PATH + '/usuarios/index';
export const getDoctors = apiDomain + EHEALTH_PATH + '/usuarios/professionals';
export const getUsuarioUrl = apiDomain + EHEALTH_PATH + '/usuarios/get';
export const createUsuarioUrl = apiDomain + EHEALTH_PATH + '/usuarios/create';
export const deleteUsuarioUrl = apiDomain + EHEALTH_PATH + '/usuarios/delete';
export const editUsuarioUrl = apiDomain + EHEALTH_PATH + '/usuarios/edit';
export const editImageUserUrl = apiDomain + EHEALTH_PATH + '/usuarios/editImage';
export const activarPasarelaUrl = apiDomain + EHEALTH_PATH + '/usuarios/activarPasarela';
export const uploadDocumentIdUrl = apiDomain + EHEALTH_PATH + '/usuarios/uploadDNI';
export const registerErecipeProf = apiDomain + NO_AUTH_PATH + '/ereceta/register';
export const uploadUsersCSVUrl = apiDomain + EHEALTH_PATH + '/companies/uploadUsersCSV';
export const editPasswordUsuarioUrl = apiDomain + EHEALTH_PATH + '/usuarios/editPassword';

export const newCreateUserUrl = apiDomain + 'api/user-management/users';
export const getUserUrl = apiDomain + 'api/user-management/users';

//companies
export const companiesUrl = apiDomain + EHEALTH_PATH + '/companies/index';
export const listCompaniesUrl = apiDomain + EHEALTH_PATH + '/companies';
export const createCompanyUrl = apiDomain + EHEALTH_PATH + '/companies/create';
export const deleteCompanyUrl = apiDomain + EHEALTH_PATH + '/companies/delete';
export const getCompanyUrl = apiDomain + EHEALTH_PATH + '/companies/get';
export const licensesEmpresaUrl = apiDomain + EHEALTH_PATH + '/companies/licenses';
export const editCompanyUrl = apiDomain + EHEALTH_PATH + '/companies/edit';
export const saveCompaniesPermission = apiDomain + EHEALTH_PATH + '/companies/editPermissions';
export const saveCompaniesStyles = apiDomain + EHEALTH_PATH + '/companies/editStyles';
export function newCompaniesPermission(companyId) {
  return `${apiDomain}api/companies/${companyId}/permissions`;
}

// Electronic Prescriptions
export function electronicPendingPrescription(prescriptionId) {
  return `${apiDomain}api/electronic-prescription/pending-prescriptions/${prescriptionId}`;
}

export const electronicPendingPrescriptionBatch = `${apiDomain}api/electronic-prescription/pending-prescriptions/batch`;

// Favorite Medicines
export function favoriteUrl(medicineId, professionalId) {
  return `${apiDomain}api/electronic-prescription/professionals/${professionalId}/favorite-medicines/${medicineId}`;
}

//productosUrl
export const productosUrl = apiDomain + EHEALTH_PATH + '/productos/index';
export const createProductoUrl = apiDomain + EHEALTH_PATH + '/productos/create';
export const deleteProductoUrl = apiDomain + EHEALTH_PATH + '/productos/delete';

//administradores
export const resenasUrl = apiDomain + EHEALTH_PATH + '/resenas/index';
export const resenasEditUrl = apiDomain + EHEALTH_PATH + '/resenas/edit';
export const dispositivosUrl = apiDomain + EHEALTH_PATH + '/dispositivos/index';
export const dispositivosNewUrl = apiDomain + EHEALTH_PATH + '/dispositivos/nuevo';
export const dispositivosEditUrl = apiDomain + EHEALTH_PATH + '/dispositivos/edit';

// Treatments
export const treatmentSaveUrl = apiDomain + EHEALTH_PATH + '/treatments';
export const prescriptionSaveUrl = apiDomain + EHEALTH_PATH + '/prescriptions';
export const prescriptionCancelUrl = apiDomain + EHEALTH_PATH + '/prescriptions/cancel';
export const prescriptionModifyUrl = apiDomain + EHEALTH_PATH + '/prescriptions/modify';
export const medicamentAnulateUrl = apiDomain + EHEALTH_PATH + '/prescriptions/anulateMedicament';
export const medicamentUnlockUrl = apiDomain + EHEALTH_PATH + '/prescriptions/unlockMedicament';
export const getTreatmentsUrl = apiDomain + EHEALTH_PATH + '/treatments/search';
export const deleteTreatment = apiDomain + EHEALTH_PATH + '/treatments/';
export const treatmentsUrl = apiDomain + EHEALTH_PATH + '/treatments/';
export const getTreatmentUrl = apiDomain + EHEALTH_PATH + '/treatments/treatment';
export const emitPrescriptionUrl = apiDomain + EHEALTH_PATH + '/prescriptions/emit';
export const datamatrixUrl = apiDomain + NO_AUTH_PATH + '/datamatrix';
export const hipUrl = apiDomain + NO_AUTH_PATH + '/hip';
export const checkCollegiateAllowPrescribe = apiDomain + EHEALTH_PATH + '/prescriptions/checkCollegiateAllowPrescribe';
export const checkCurrentMedicineForPatient =
  apiDomain + EHEALTH_PATH + '/prescriptions/checkCurrentMedicineForPatient';
export const pdfPrescriptionUrl = apiDomain + 'api/patient';

export const getUnverifiedProfessionals = apiDomain + EHEALTH_PATH + '/unverifiedProfessionals';
export const verifyProfessionals = apiDomain + EHEALTH_PATH + '/verifyProfessionals';
export const alreadyVerifiedProfessionals = apiDomain + EHEALTH_PATH + '/alreadyVerifiedProfessionals';
export const rejectProfessional = apiDomain + EHEALTH_PATH + '/rejectProfessional';
export const requestMoreInformation = apiDomain + EHEALTH_PATH + '/requestMoreInformation';
export const getDniFront = apiDomain + NO_AUTH_PATH + '/getDniFront';
export const getDniBack = apiDomain + NO_AUTH_PATH + '/getDniBack';
export const getProfessionalLanding = apiDomain + 'api/patient';

// Patients
export const getDocumentsUrl = '/documents';
export const getPrescriptionsUrl = '/prescriptions';
export const getPrescriptionsSearchUrl = apiDomain + EHEALTH_PATH + '/prescriptions/searchPrescriptions';
export const batchEmitPrescriptionsUrl = apiDomain + EHEALTH_PATH + '/prescriptions/batch/emit';
export const getMessagesUrl = '/messages';
export const getAppointmentsUrl = '/appointments';
export const subscriptionsUrl = '/subscriptions';
export const beneficiariesUrl = '/beneficiaries';
export const getPacksUrl = '/packs';
export const getAvailability = '/availability';
export const getProfessionalsUrl = '/professionals';
export const listCouponsUrl = '/coupons/professionals';
export const checkCouponUrl = '/coupons';
export const paymentIntentUrl = '/paymentIntents';
export const subscriptionIntentUrl = '/subscriptionIntents';

//Informed consent
export const getShowInformedConsentUrl = apiDomain + 'api/informed-consent/show';
export const downloadInformedConsentUrl = apiDomain + 'api/informed-consent/download';
export const getAcceptInformedConsentUrl = apiDomain + 'api/informed-consent';
export const getListPatientInformedConsentsUrl = apiDomain + 'api/informed-consent/list/';

//Webphone
export const webphoneUrl = apiDomain + 'api/webphone';

export const professionalsUrl = apiDomain + 'api/professionals';

//MedicalTests
export const medicalTestsUrl = apiDomain + 'api/medical-test/v2/';

//Marketplace
export const marketplaceUrl = apiDomain + 'api/marketplace/v2/';

// TOC
export const tocUrl = apiDomain + 'api/integrations/toc';

// 2FA
export const requestReset2faUrl = apiDomain + 'api/user-management/2fa/reset';
export function reset2faUrl(userId) {
  return `${apiDomain}api/user-management/${userId}/2fa`;
}

// PersonalDoctor
export const personalDoctorUrl = apiDomain + EHEALTH_PATH + '/personal-doctors';
export function personalDoctorPatientsUrl(id) {
  return `${newPatientsUrl}/${id}/personal-doctors`;
}
export function personalDoctorPatientAssignmentsUrl(id) {
  return `${personalDoctorPatientsUrl(id)}/assignments`;
}

// Parapharmacy
const parapharmacyUrl = apiDomain + 'api/parapharmacy-prescription';
export function parapharmacyTemplatesUrl(id) {
  return `${parapharmacyUrl}/professionals/${id}/templates`;
}

export function parapharmacyProductsUrl(id) {
  return `${parapharmacyUrl}/professionals/${id}/products`;
}

export function parapharmacyProfessionalPrescriptionsUrl(id) {
  return `${parapharmacyUrl}/professionals/${id}/prescriptions`;
}

export function parapharmacyTemplateUrl(id) {
  return `${parapharmacyUrl}/templates/${id}/form`;
}

export function parapharmacyPreviewUrl(id) {
  return `${parapharmacyUrl}/templates/${id}/report`;
}

export function parapharmacyPatientPrescriptionsUrl(id) {
  return `${parapharmacyUrl}/patients/${id}/prescriptions`;
}

export function parapharmacyPrescriptionPdfUrl(id) {
  return `${parapharmacyUrl}/prescriptions/${id}/report`;
}

export const parapharmacyEmitUrl = `${parapharmacyUrl}/prescriptions`;

export const clientId = '2';
export const clientSecret = 'MCUgoqjjbQxcCCNQhhi8QN2JpwzGtbsybFm9Lups';

export const getHeader = function() {
  const tokenData = JSON.parse(window.localStorage.getItem('auth_ehealth'));
  const token = tokenData !== null && typeof tokenData.access_token !== 'undefined' ? tokenData.access_token : '';
  return {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token,
  };
};

export const getHeaderMultimedia = function() {
  const tokenData = JSON.parse(window.localStorage.getItem('auth_ehealth'));
  return {
    Accept: 'application/json',
    Authorization: 'Bearer ' + tokenData.access_token,
    'Content-Type': 'multipart/form-data',
  };
};

export const getAuthUser = function() {
  return JSON.parse(window.localStorage.getItem('auth_ehealth'));
};

export const getPatientId = function(patientId = null) {
  if (patientId === null) {
    patientId = getAuthUser() && typeof getAuthUser().patient.id !== 'undefined' ? getAuthUser().patient.id : 0;
  }
  return patientId;
};

export const urlPatient = function(path, limit, page, patientId = null) {
  patientId = getPatientId(patientId);
  let route = apiDomain + PATIENT_PATH + '/' + patientId + path;
  if (limit > 0 && page > 0) {
    route += '?limit=' + limit + '&page=' + page;
  }
  return route;
};

export const urlPatientFilter = function(path, queryFilter, patientId = null) {
  patientId = getPatientId(patientId);
  let route = apiDomain + PATIENT_PATH + '/' + patientId + path;
  if (queryFilter !== '') {
    route += queryFilter;
  }
  return route;
};

export const apiPath = function(path) {
  return apiDomain + EHEALTH_PATH + path;
};

// Minors Treatment
export function ageGroupSpecialtiesUrl(professionalId) {
  return `${apiDomain}${EHEALTH_PATH}/professionals/${professionalId}/specialties/properties`;
}

// Tirea Service
export function tireaServiceUrl(collegiateNumber, professionalId, patientId) {
  return `${api2Domain}tirea/service?collegiateNumber=${collegiateNumber}&professionalId=${professionalId}&patientId=${patientId}`;
}

// Company notifications
export function companyNotificationsServiceUrl(companyId) {
  return `${api2Domain}notifications/companies/${companyId}/settings`;
}


// SickLeave management
export function sickLeaveManagement(professionalId) {
  return `${apiDomain}${EHEALTH_PATH}/professionals/${professionalId}/status`;
}

// Professional languages
export function professionalLanguages(professionalId) {
  return `${apiDomain}${EHEALTH_PATH}/professionals/${professionalId}/languages`;
}
