export default {
  card_number: 'Card Number',
  or: 'or',

  account: {
    chooseImage: 'Choose your profile image',
    chooseImageText: 'Click and drag to reposition. Use the trackpad or your mouse to zoom in or out.',
    cancel: 'Cancel',
    continue: 'Continue',
    noImage: "You haven't chosen any profile image.",
    fileNotAllowed: 'Unable to upload the file. This file type is not supported.',
    fileTooBig: 'The file is too big. {fileSize} maximum file size.',
    uploadError: 'Ops! Something went wrong. Please, try again.',
    idPassport: 'ID / Passport',
    biography: 'Biography',
    editBio: 'Edit your bio in {lang}',
    successEdit: 'Your profile has been updated.',
    security: 'Security',
    passwordHint: 'A strong password will prevent your account from being easily hacked',
    change: 'Change',
    marketplaceName: 'Marketplace name',
  },
  agenda: {
    lunes: 'poniedziałek',
    martes: 'wtorek',
    miercoles: 'środa',
    jueves: 'czwartek',
    viernes: 'piątek',
    sabado: 'sobota',
    domingo: 'niedziela',
    lunes_redu: 'Mon',
    martes_redu: 'Tue',
    miercoles_redu: 'Wed',
    jueves_redu: 'Thu',
    viernes_redu: 'Fri',
    sabado_redu: 'Sat',
    domingo_redu: 'Sun',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    horainicio: 'czas rozpoczęcia',
    horafin: 'czas zakonczenia',
    configuracion: 'ustawienia kalendarza',
    disponibilidad: 'kalendarz dostępności',
    nodisponible: 'dni niedostępne',
    disponible: 'Available Days',
    sin_disponibles: 'nie ustawiłeś/ łas żadnych ograniczeń dostępności w najbliższym czasie',
    nuevo_nodisponible: 'stwórz ograniczenia dostępności',
    editar_nodisponible: 'edytuj ograniczenia niedostępności',
    diacompleto: 'cały dzień',
    delete_nodisponible: 'usuń ograniczenie dostępności dla calego dnia',
    confirm_delete_nodisponible: 'Cały dzień będzie calkowicie dostępny. Chcesz kontynuować?',
    nuevo_rango: 'nowy zakres',
    hora_inicio_dia: 'There is an error in the {dia} time range',
    hora_inicio_debe_ser: 'czas rozpoczęcia musi być wcześniejszy niż czas zakończenia',
    sin_horas_asignadas: 'You currently have no assigned schedule',
  },
  appointments: {
    name: 'Video Consultation | Video Consultations',
    not_have_pending: 'You do not have pending video consultations.',
    error: 'There was a problem loading the last messages',
    onlineBookingNotAvailable: 'Online booking not available. Select another date',
    dateRequired: 'Please select the time suits you best for the video consultation',
  },
  asignar: {
    titulo: 'Asignar consulta',
    texto_video: '¿Esta seguro que desea asignarse esta videoconsulta y por tanto se compromete a atender al paciente?',
    texto_mensaje: '¿Esta seguro que desea asignarse este mensaje y por tanto se compromete a atender al paciente?',
    asignada_correctamente: 'Se ha asignado la consulta correctamente',
  },
  beneficiaries: {
    no_beneficiaries: "You haven't added people you care for yet",
    relationship: 'Relationship',
    new: 'New',
  },
  calendario: {
    especialidades: 'Calendar by Specialty',
    professionals: 'Professionals Calendar',
    select_professional: 'Select Professional',
    nodisponible: 'Not Available',
  },
  common: {
    search: 'Search',
    aceptar: 'zaakceptuj',
    condiciones: 'ogólne warunki użytkowania',
    cancel: 'usuń',
    ok: 'OK',
    confirm: 'potwierdź',
    save: 'zapisz',
    name: 'imię',
    category: 'Category',
    lang: 'język',
    lang_platform: 'Język platformy',
    lang_estracto: 'Język biografii',
    prescription: 'recepty | recepty',
    documents: 'dokumenty',
    chequeos: 'Chequeos',
    messaging: 'wiadomości',
    videoconference: 'wideokonsultacje',
    videoconferences: 'wideokonsultacje',
    presential: 'Face-to-face appointment',
    pack: 'Pakiet',
    cita: 'Konsultacje',
    country: 'Country',
    province: 'województwo',
    location: 'miasto',
    cp: 'kod pocztowy',
    address: 'ulica',
    spanish: 'hiszpański',
    english: 'angielski',
    catalan: 'Kataloński',
    portuguese: 'Portugalski',
    polaco: 'Polski',
    date: 'data',
    size: 'Size',
    type: 'Type',
    actions: 'działania',
    date_up: 'data wypisu',
    of: 'of',
    changes: 'zmiany',
    closed_session: 'wylogowoano',
    closed_session_expired: 'ze względów bezpieczeństwa zostałaś wylogowana',
    volver_atras: 'wstecz',
    acceso_denegado: 'Przepraszamy, odmowa dostępu.',
    emitir_receta: 'wyślij receptę',
    modify_recipe: 'Modify prescription',
    delete_recipe_confirm: 'Are you sure you want to delete the selected prescription?',
    delete_recipe: 'Delete prescription',
    emitSignPrescription: 'Sign and issue prescription',
    alreadyDiscardedPrescription:
      "You've created a new prescription within an expired treatment plan. Please start a new treatment and include the new prescription there.",
    descripcion: 'opis',
    obligatorio: 'pole obowiązkowe',
    min_required: 'The field must have at least {tam} characters',
    paciente: 'pacjent',
    cualquiera: 'lub',
    activado: 'aktywny',
    desactivado: 'nieaktywny',
    gratuita: 'darmowy',
    gratuito: 'darmowy',
    precio: 'cena',
    duracion: 'czas trwania',
    detalles: 'szczegóły',
    hora: 'czas trwania',
    edit: 'Edit',
    delete: 'usuń',
    emitir: 'wysłano',
    no_registros: 'nie ma zapisów',
    status: 'status',
    estado_servicio: 'Status of Service',
    no_access: 'nie masz uprawnień dostępu do tej sekcji',
    prox_consultas: 'nadchodzące konsultacje | nadchodzące konsultacje',
    consultas_personales: 'Consultas Personales',
    ult_mensajes: 'ostatnie wiadomości',
    mes_redu: 'STY, LUT, MAR, KWI, MAJ, CZE, LIP, SIE, WRZ, PAZ, LIS, GRU',
    enviar_enlace: 'wyślij link',
    version_trial: 'ta funkcja jest nieaktywna  w Twojej wersji demo',
    titulo_salir_sin_guardar: 'wyjdź bez zachowania zmian',
    salir_sin_guardar: 'wprowadziłeś zmiany w dokumencie. Czy chcesz  zamknąć dokument bez zapisywania?',
    fecha_inicio: 'Start Date',
    fecha_fin: 'End Date',
    landing: 'Custom Landing Page',
    backgroundcolor: 'Background Color',
    nuevo_servicio: 'New Service',
    otro: 'Inne',
    continuar: 'Next',
    filtrar: 'Filter',
    sin_atender: 'Consultas Sin Atender',
    medicos_disponibles: 'Médicos Disponibles',
    semana: 'Week',
    movil: 'phone',
    celular: 'phone',
    reconocimiento: 'Rozpoznanie medyczne',
    apto: 'Fit',
    no_apto: 'Unfit',
    sin_verificar: 'Unverified',
    aviso_legal: 'Legal Notice',
    term_and_conditions: 'Terms and Conditions',
    term_and_conditions_signup: 'Terms and Conditions (Sign up)',
    term_and_conditions_without_tax: 'Terms without tax',
    politica_privacidad: 'Privacy Policy',
    politica_cookies: 'Cookies',
    aboutus: 'About us',
    title: 'Title',
    professional: 'Professional',
    numero_seg_social: 'Health Card Numbers',
    filters: 'Filters',
    yes: 'Yes',
    no: 'No',
    active_web: 'Activate web',
    inactive_web: 'Web disabled',
    close: 'Close',
    availableRoles: 'Available roles',
    undefined: 'Undefined',
    admin: 'Admin',
    receptionist: 'Assistant',
    rrhh: 'RRHH',
    patient: 'Patient',
    man: 'Man',
    woman: 'Woman',
    unspecified: 'Unspecified',
    more: 'More',
    taxPercent: 'Tax Percent',
    taxType: 'Tax Type',
    add: 'Add',
    fieldIncomplete: 'You must fill in the {field} field',
    download_pdf: 'Download PDF',
    go_to_profile: 'Go to profile',
    notice: 'Notice',
    accepted_at: 'Accepted at',
    chat: 'Chat',
    participants: 'Participants',
    consultation_subject: 'Reason for consultation',
    short_date_format: 'DD/MM/YYYY',
    subscriptions_temporarily_disabled:
      "We're sorry, subscriptions are temporarily disabled. Please try again in a few days.",
    show_to_hiring: 'Visible for hiring on the web',
    hide_to_hiring: 'Not visible for hiring on the web',

    date_placeholder: 'mm/dd/aaaa',
    years: 'years',
    max_n_characters: 'Max {chars} characters',
    return_back: 'Return',
  },
  cupones: {
    crear: 'Create Coupon',
    codigo: 'kod',
    descuento: 'Discount',
    caducidad: 'Expiration',
    num_usos: 'Number of uses',
    sin_cupones: 'No coupon has been configured',
    crear_nuevo: 'Create New Coupon',
    editar: 'Edit the coupon',
    eliminar_titulo: 'Remove the coupon',
    eliminar_texto: 'The coupon will no longer be active. Are you sure you want to delete the coupon?',
    min_code: 'The code must have at least 6 characters',
    tam_code: 'The code must have {num} characters',
    porusos: 'By uses',
    porfecha: 'By date',
    nocaduca: 'Does not expire',
    error_crear_existe_codigo: 'The coupon has not been created. A coupon with the code already exists: ',
    error_editar_existe_codigo: 'The coupon has not been edited. A coupon with the code already exists: ',
    success_create: 'The coupon has been created correctly',
    caduca_por_usos: 'This coupon expires by uses.',
    sin_caducidad: 'Coupon without expiration.',
    caduca_por_fecha: 'This coupon expires on ',
    usarlo_cualquiera: 'It can be used by any patient.',
    usarlo_paciente: 'Can only be used by: ',
    show_details: 'Show details',
    confirmGenericMessage: "Are you sure? This action can't be undone",
    and: 'and',
    go_home: 'Go back',
    image_text: 'Colour image up to 5 MB and up to 8000 by 8000px in JPG format',
    dni_front: 'Upload your ID documentation (front)',
    dni_back: 'Upload your ID documentation (reverse)',
    price_v_standard: 'Standard V. price',
    range_of_hours: 'Range of hours',
    registration_status: 'Registration status',
  },
  datatable: {
    rows_per_page: 'Wiersze na stronie',
    all: 'wszystkie',
  },
  documentos: {
    nuevo: 'New File',
    confirmar_subida_titulo: 'Upload File',
    confirmar_subida_texto: 'Are you sure you want to upload the selected file?',
    subido_correctamente: 'The file has been uploaded correctly',
    csv_uploaded:
      'The file has been uploaded correctly. This process can take several time. If after half an hour the data has not been updated correctly, contact support',
    subir_reconocimiento: 'Prześlij certyfikat badania lekarskiego',
    subir_documento: 'Please upload a document that validates this decision.',
    subir_certificado: 'Upload certificate',
    subir_renuncia: 'Upload waiver',
    certificado: 'Certificate',
    renuncia: 'Waiver',
  },
  documents: {
    upload_document: 'Upload File',
    no_documents: 'You do not have documents',
    drag_and_drop: 'Drag and drop your file here',
    browse_file: 'browse file',
    supported_files: 'Supported files',
    size_limited: '{num} MB limited',
    upload_new: 'Upload New',
    select_professional: 'Health professional who wants to enable access to the document',
    are_you_sure_upload: 'Are you sure you want to upload a new document?',
    video: 'Video',
    chat: 'Chat',
    prescription: 'Prescription',
    triage: 'Triage',
    diagnosis: 'Diagnosis',
    medical_report: 'Medical Report',
    other: 'Document',
    electronic_prescription: 'Electronic Prescription',
  },
  errors: {
    not_access_patient: 'Błąd podczas próby dostępu do danych pacjenta',
    edit_patient: 'bład podczas edycji danych pacjenta',
    edit_user: 'błąd podczas edycji konta',
    existing_patient: 'Pacjent jest już zarejestrowany',
    form_invalid: 'proszę wypełnić pola obowiązkowe',
    form_empty: 'formularz jest pusty, wypełnij przynajmniej jedno pole',
    email_required: 'adres mailowy jest obowiązkowy',
    email_or_phone_required: 'Adres e-mail / telefon jest wymagany',
    password_required: 'hasło jest obowiązkowe',
    usr_pw_incorrect: 'nieprawidłowa nazwa użytkownika lub  hasło',
    email_invalid: 'nieprawidłowy adres email',
    email_repeat: 'adres mailowy nie pasuje',
    try_again: 'błąd podczas operacji, spróbuj jeszcze raz',
    save_history: 'błąd podczas zapisywania  historii choroby',
    existing_user: 'do tego maila lub nazwy użytkownika jest już przypisane konto',
    existing_user_in_company:
      'There is already a user with that email and that company. Please, choose another email address or modify the company with which you want to associate this professional',
    existing_user_without_company:
      'There is already a professional not associated with any company and with the same email. Please choose another email address',
    horaFinMenorHoraInicio: 'czas zakończenia nie może być wcześniejszy niż czas rozpoczęcia',
    usr_dado_baja: 'Konto nie jest teraz aktywne',
    must_be_at_least_13_years: 'Must be at least 13 years of age',
    trial_caducado: 'Okres próbny wygasł, jeśli chcesz go przedłużyć, skontaktuj się z nami',
    email_no_exist: 'E-mail nie jest zarejestrowany w {name} jako email specjalisty',
    mayor_cero: 'Cyfra musi być większa niż 0 | Wartość musi być większa lub równa 0',
    user_exist: 'ten adres e-mail jest już zarejestrowany w aplikacji',
    file_max_upload: 'Plik przekracza maksymalną wielkość 100MB',
    input_tam_maximo_superado: 'The input {input} exceeds the maximum size allowed ({tam} characters)',
    factura_no_disponible:
      'It is not possible download this invoice at this momment. Please contact us to resolve the issue',
    date_required: 'The date of birth is mandatory',
    phone_video_error:
      "You cannot select more than one patient to create an appointment from the patient's phone number",
    max_patients_multi: 'You cannot select more than three patients for a multiconsultation',
    phone_empty: 'You must enter the phone number',
    incorrect_hour: 'The time format is not correct. Example: 10:30',
    incorrect_date: 'The date format is not correct',
    date_must_be_greater_than_now: 'Date and time must be greater than now',
    incorrect_colegiado: 'The collegiate number must have between 8 and 9 characters',
    incorrect_collegiate_psychologist: 'Incorrect psychology collegiate number',
    emit_e_prescription:
      'There has been an error with the E-Prescription. Please report the issue, and we will resolve the error as soon as possible.',
    incorrect_colegiado_emit:
      'Your collegiate number is not correct, please go to your profile and update the registration number',
    incorrect_collegiate_number: 'The collegiate number must be correct to emit the prescription',
    invalid_collegiate_number:
      'Your medical registration number is invalid. Please, contact us directly by email at {email} to fix it and verify the number. *Warning: The medical registration number has 8 or 9 digits.',
    collegiate_not_allow_to_prescribe:
      'With your current licence number you can not prescribe drugs to your patients. Please access your account profile, update your license number and try again',
    invalid_patient_document:
      'The patient DNI / ID number you entered is not valid. Please update the number in their profile and try again',
    freemium_no_multi:
      'With the Freemium version, you cannot create multiconsultation. Contact your sales representative to upgrade to the Premium version.',
    gender_required: 'Gender is required',
    relation_required: 'Relation is required',
    type_prof: 'You must select a type of professional',
    bad_request: 'An error has occurred, parameters are missing to complete the action',
    unauthorized: 'You do not have sufficient permissions to perform this action',
    must_be_adult: 'User must be adult',
    specialty_required: 'It is mandatory to select at least one specialty',
    patient_has_no_email_message:
      'The patient has no email. Please edit his or her data and enter a valid email to be able to emit the electronic prescription.',
    not_found_title: 'This page is not available',
    not_found_message:
      'The link you used may be broken or you reached a deleted page. Please check the link you are using is correct.',
    server_down: 'Sorry, the server is down.',
  },
  facturacion: {
    concepto: 'Koncepcja',
    importe: 'Kwota',
    pagosrecibidos: 'Payments received',
    dateFactura: 'Date Invoice',
    datePay: 'Date Payment',
    without_payments: 'You haven´t received any payments in the time period selected',
    puede_tardar: 'Please be patient while we retrieve the information from the payment platform',
    rango_fechas: 'The time period cannot exede {num} days',
    info_sobre_facturas:
      'You will not see here the invoices for the payments made during the last five days due to the processing time required by our online payment provider',
    aceptar_transaccion: 'Accept Transaction',
    aceptar_transaccion_descrip: 'Are you sure you want to confirm the selected transaction?',
    cancelar_transaccion: 'Cancel transaction',
    cancelar_transaccion_descrip: 'Are you sure you want to cancel the selected transaction?',
    select_professional: 'Select a professional',
  },
  hiring: {
    service: 'Hire Service',
    appointment: 'Hire Video Consultation',
    message: 'Hire Chat',
    pack: 'Hire Pack',
    subscription: 'Hire Subscription',
    select_receiver: 'The service is not for you? Choose the person for whom you are requesting this service',
    reason_of_consultation: 'Write the reason for your video consultation',
    consultation_details: 'Details of your appointment',
    professional_will_confirm:
      'The health professional will confirm your booking or will suggest a new appointment as soon as possible',
    professional_will_approve_pack: 'The health professional will get in touch with you as soon as possible',
    have_promotional_code: 'Do you have a Promotional Code?',
    card_payment: 'Card payment',
    proceed_to_payment: 'Proceed to payment',
    discount_applied: '{num}% discount applied',
    add_document:
      'Feel free to attach any relevant document as test results, pictures etc. to faclilitate the diagnostic',
    document_added: 'Document added to the message',
    note_to_professional: 'You can write a note for the health professional',
    monthly_fee_will_charged:
      'A monthly fee will be charged for your active subscription. You can cancel this service form your account',
    send: 'Send',
    free_with_your_subscription: 'You already have an active subscription that has this service included',
    appointment_proposed_by_professional:
      'This is the appointment proposed by the professional. As soon as we validate your payment, we will send you instructions on how to connect to the video consultation by email.',
    you_have_period_to_confirm:
      'You have 2 days to confirm this appointment. After that, the professional can reassign this appointment to another patient or cancel it without prior notice',
    appreciate_your_understanding: 'Thank you for your comprehension.',
  },
  historia: {
    antecedentes_personales: 'Antecedentes Personales',
    enfermedades_infancia: 'Enfermedades en la infancia',
    enfermedades_adulta: 'Enfermedades en edad adulta',
    medicamentos: 'Medicamentos habituales/Crónico',
    intervenciones: 'Intervenciones Quirúrgicas',
    actividad_laboral: 'Actividad Laboral',
    antecedentes_familiares: 'Antecedentes Familiares ',
    alergias: 'Allergies',
  },
  mensajeria: {
    nuevo_mensaje: 'New message',
    pendientes: 'Pendings',
    abiertos: 'Open Messages',
    abierto: 'Open',
    cerrados: 'Closed Messages',
    enviar: 'Send',
    para: 'To',
    motivo: 'Subject',
    subject: 'Subject',
    mensaje: 'Message',
    mensajes: 'Messages',
    select_user: 'Select recipient',
    enviar_nuevo_mensaje: 'Send a new message',
    marcar_como_atendida: 'Mark as solved',
    sin_mensajes_pendientes: "You don't have any pending messages",
    sin_mensajes_abiertos: 'At this time, you have no messaging open to answer with any patient',
    sin_mensajes_atendidos: 'You have not yet marked any messages as closed.',
    archivos_adjuntos: 'Attached Files',
    has_attachments: 'Has attachments',
    attach_file: 'Attach file',
    mensaje_con_adjuntos: 'The message will be sent with an attachment',
    add_texto_al_mensaje: 'Add text to the response to send the message',
    add_destinatario_al_mensaje: 'Indicate the recipient of the message',
    enviado_correctamente: 'Message sent correctly',
    enviado_correctamente_error_adjunto:
      'Message sent correctly, but the file could not be attached, check that it does not exceed the maximum allowed size and that it is an image or a pdf',
    error_enviando: 'The message could not be sent. Try it again later',
    atendido_correctamente: 'The message has been correctly marked as solved',
    error_atendiendo: 'Error marking the message as answered, try again later',
    confirm_atendida:
      'The courier service will be marked as solved. If the patient wishes to reconnect, they must request a new messaging service. Are you sure you want to close this messaging service?',
    sin_contestar: 'WITHOUT ANSWER',
    metadata_list_text: 'Other data',
    answered: 'ANSWERED',
    sent: 'SENT',
    sin_cerrar: 'OPEN',
    ver_file: 'Show File',
    delete_file: 'Delete File',
    confirmar_delete_file:
      'If you delete the document the patient will not be able to access it. Are you sure you want to delete the document?',
    modificar_name_file: 'Modify Document Name',
    nombre_file: 'Name of the Document',
    atendido: 'Closed',
    enviar_mensaje: 'Send message',
    cerrado: 'Closed',
    unread_messages: 'Unread messages',
    not_exists_thread: 'There is not a thread with an identifier {id}',
    downloaded_on: 'Downloaded on',
    pdf_footer_link: 'This PDF has been generated through the chat from ',
    see_chat: 'See chat',
    loading_chat: 'Loading chat...',
  },
  menu: {
    home: 'strona główna',
    patients: 'pacjenci',
    messaging: 'wiadomości',
    videocam: 'wideokonsultacje',
    productos: 'Products',
    calendario: 'Calendar',
    reports: 'raporty',
    chat: 'czat',
    inbox: 'skrzynka odbiorcza',
    settings: 'ustawienia',
    help: 'pomoc',
    profile: 'profil',
    close_session: 'wyjście',
    agenda: 'plan',
    configvideoconsultas: '',
    cupones: 'kupony',
    talonario: 'bloczek recept',
    firma: 'podpis',
    users: 'użytkownik',
    medicos: 'Doctors',
    metrics: 'metryki',
    packs: 'paczki',
    packcontratados: 'zaplanowane pakiety',
    contact: 'kontakt',
    empresas: 'firma',
    tarifas: 'wskaźniki',
    servicios: 'usługi',
    miweb: 'My WebSite',
    facturacion: 'Billing',
    recepcionista: 'Assistant',
    resenas: 'Reviews',
    dispositivos: 'Devices',
    citas: 'Appointments',
    transacciones: 'Transactions',
    valorespersonalizados: 'Custom Values',
    myMessages: 'Messages',
    myVideoconsultations: 'Video Consultations',
    myPrescriptions: 'Prescriptions',
    mySubscriptions: 'Subscriptions',
    myDocuments: 'Documents',
    sendUsMessage: 'Send us a Message',
    beneficiaries: 'Associated users',
    medicalTests: 'Tests',
    medicalTestTypes: 'Test Types',
    medicalTestNomenclator: 'Nomenclator',
  },
  message: {
    password: 'hasło',
    modify_password: 'edytuj haslo',
    email: 'Email',
    repeat_email: 'Confirm Email',
    intro_email: 'Wpisz email',
    intro_pw: 'Wpisz hasło',
    intro_security: 'For security reasons, to be able to change the email you must enter the password',
    email_repeat: 'powtórz email',
    remember: 'zapamiętaj hasło',
    login: 'dostęp',
    notifications: 'powiadomienia',
    showall: 'pokaż wszystko',
    reset_texto: 'Wpisz swój adres e-mail. Wyślemy Ci e-mail z linkiem do zresetowania hasła',
    reset_password: 'resetuj hasło',
    introduzca_password: 'Wpisz hasło aby aktywować konto',
    restaurar_password: 'resetuj hasło',
    password_weak: 'Weak password',
    password_not_secure: 'Password is not secure',
    password_secure: 'Password is secure',
    welcome: 'Witamy w {name}',
    new_here: 'New here? Start using our Digital Health services',
    signup: 'Sign Up',
    start: 'Start',
    already_have_an_account: 'Do you already have an account? Sign in',
    estamos_encantados: 'We are delighted to have you with us. ',
    le_guiaremos:
      '‘We will now guide you through our platform so you complete the necessary fields to start using {name} everyday.',
    password_new: 'nowe haslo',
    activar_cuenta: 'aktywuj konto',
    check_credentials: 'weryfikacja poświadczeń',
    cuenta_trial:
      'Twoje konto jest w trakcie walidacji. Wersja próbna wygaśnie jutro | Twoje konto jest w trakcie walidacji. Wersja próbna wygaśnie za {dni} dn',
    double_auth_title: 'Two step verification',
    double_auth_desc: 'This extra step indicates that it is you who are trying to log in.',
    double_auth_msg_one: 'An email has just been sent to ',
    double_auth_msg_two: 'with a 6-digit verification code, please enter it below.',
    double_auth_email_again: 'Have you not received the email? Resend',
    double_auth_email_sended: 'The code has been resent',
    double_auth_error_code: 'The code is wrong, try again',
    double_auth_empty_code: 'You must fill in the code',
    double_auth_escribe_codigo: 'Wpisz kod',
    double_auth_placeholder: 'Kod',
    select_an_account: 'Select an account',
    acceptConditions:
      'I have read and accept the <a href="{privacyPolicyLink}" target="_blank">privacy policy</a> and <a href="{legalLink}" target="_blank">legal notice</a>',
    acceptGeneralConditions:
      'I have read and accept the <a href="{generalConditionsLink}" target="_blank">general conditions and data processing</a>',
    acceptGeneralConditions_LOPDGDD:
      'I have read and accept the <a href="{generalConditionsLink}" target="_blank">general conditions and data processing</a>',
  },
  messages: {
    name: 'Private Messagge | Private Messagges',
    new: 'New',
    unsolved: 'Pending',
    solved: 'Closed',
    all: 'All',
    write_your_message_here: 'Write your message here...',
    write_your_consultation: 'Write your consultation to {name}',
    not_have_pending: "No messages",
    error: 'There was a problem loading the last messages',
    reset_filter: 'Reset Filter',
    show_messages: 'Show Messages',
    message_closed: 'The message is closed',
    to_me: 'To: me',
    to: 'To',
    me: 'me',
  },
  notificaciones: {
    mensajeria: 'Masz oczekującą wiadomość | Masz {count} oczekujące wiadomości ',
    video_confirm: 'potwierdź oczekującą wideokonsultację/ Masz (count) wideokonsultacje do potwierdzenia',
    video_hoy: 'Dzisiaj masz wideokonsultację/ dzisiaj masz (count) wideokonslultacji',
    prescriptions: 'You have a prescription pending to emit | You have {count} prescriptions pending to emit',
  },
  packs: {
    name: 'Pack |Packs',
    crear: 'Create Pack',
    packs_configurados: 'Hired packs',
    packs_contratados: 'wynaj ete paczki',
    num_sesiones: 'Number of Sessions',
    editar: 'Edit Pack',
    eliminar_titulo: 'Delete Pack',
    eliminar_texto:
      'Patients will not be able to hire this Pack in the future. Previously hired packs will not be affected.',
    eliminar_confirmar: 'Are you sure you want to delete this pack?',
    sin_packs: 'No packs has been configured',
    completados: 'Completed',
    sin_pendientes: 'At this time you do not have any uncompleted hired packs',
    sin_completados: 'You have not completed any hired pack',
    fecha_contratado: 'Hired Date',
    sesiones_totales: 'Total Sessions',
    sesiones_restantes: 'Remaining Sessions',
    marcar_como_completado: 'Mark as Completed',
    marcar_como_completado_texto: 'The pack still has unused sessions. Are you sure you want to mark is as complete?',
    success_complete: 'The pack has been marked as completed correctly',
    sesion: 'Session',
    not_have_hired: 'You have no contracted pack',
    num_pending: '1 pending session | {num} pending sessions',
  },
  patient: {
    patients: 'pacjenci',
    search: 'szukaj pacjenta',
    without_patients: 'nie masz żadnych powiązanych pacjentów',
    new: 'mowy pacjent',
    edit: 'edytuj pacjenta',
    delete: 'usuń pacjenta',
    confirm_delete: 'Wybrany pacjent nie będzie mógł korzystać z twoich usług. Czy chcesz usunąć tego pacjenta?',
    personal_data_mayus: 'DANE OSOBOWE',
    personal_data: 'Dane osobowe',
    history_clinical: 'historia kliniczna',
    history_clinic: 'historia kliniczna',
    first_name: 'imię',
    last_name: 'nazwisko',
    document_id: 'nr  dowodu',
    cedula_or_passport: 'Cedula or Passport',
    birthdate: 'data urodzenia',
    phone: 'numer telefonu',
    gender: 'płeć',
    female: 'kobieta',
    male: 'mężczyzna',
    other: 'Other',
    delete_success: 'pacjent został usuniety',
    delete_error: 'błąd podczas usuwania pacjenta. Spróbuj ponownie',
    full_name_required: 'imię i nazwisko są obowiązowe',
    too_long_name: 'Name cannot be longer than 50 characters',
    too_long_surname: 'Last name cannot be longer than 50 characters',
    dni_required: 'nr dowodu jest obowiązkowy',
    type_dni_required: 'ID Document Type is required',
    dni_invalid: 'Niewłaściwy format numeru dokumentu',
    dni_invalid_letra: 'Incorrect ID, the letter of the NIF does not correspond',
    birthdate_invalid: 'data urodzenia musi być wcześniejsza od dzisiejszej',
    observations: 'oberwacje',
    antecedentes_familiares: 'historia rodziny',
    antecedentes_maternos: 'historia Matki',
    antecedentes_paternos: 'historia Ojca',
    antecedentes_hermanos: 'historia rodzeństwa',
    antecedentes_otros: 'Antecedentes otros familiares',
    antecedentes_personales: 'historia  pacjenta',
    antecedentes_cirugias: 'historia operacji i zabiegów chirurgicznych',
    alergias_medicamentos: 'alergie na leki',
    otras_alergias: 'inne alergie',
    enfermedades_cronicas: 'choroby przewlekłe',
    historial_psiquiatria: 'historia zdrowia psychicznego',
    motivo_consulta: 'powody konsultacji',
    antecedentes_somaticos: 'historia zdrowia somatycznego',
    antecedentes_psiquiatricos: 'historia zdrowia psychicznego',
    genograma: 'historia rodziny',
    psicobiografia: 'psychobiografia',
    habitos_toxicos: 'szkodliwe nawyki',
    anamnesis: 'anamneza',
    diagnosticos: 'diagnoza',
    evoluciones: 'ewolucje',
    recomendaciones: 'zalecenia',
    nuevo_diagnostico: 'nowa diagnoza',
    editar_diagnostico: 'edytuj diagnozę',
    sin_diagnosticos: 'ten pacjent nie jest jeszcze zdiagnozowany',
    delete_diagnostico: 'usuń diagnozę',
    confirm_delete_diagnostico: 'czy chcesz usunąć tę diagnozę?',
    nueva_evolucion: 'nowa ewolucja',
    editar_evolucion: 'edytuj ewolucję',
    sin_evoluciones: 'No evolution has yet been added to this patient',
    delete_evolucion: 'usuń ewolucję',
    confirm_delete_evolucion: 'Czy chcesz usunąć tę ewolucję?',
    sin_documentos: 'ten pacjent nie ma żadnych  danych',
    sin_recetas: 'ten pacjent nie ma zleconych leków',
    generarinforme: 'utwórz raport',
    pais: 'kraj',
    evolucion: 'ewolucja',
    exploracion: 'badanie',
    juicioclinico: 'ocena kliniczna',
    tratamiento: 'leczenie',
    num_historia: 'No. History',
    notas: 'Internal Notes (The patient will not be able to see this information and will not appear in the report)',
    tipo_documento: 'Dokument Tożsamości',
    pasaporte: 'Paszport',
    enviado_mensaje_vincular:
      'The patient is already registered. We have sent an email to the patient to accept the link between health professional and patient',
    relacion: 'Relationship',
    datos_tutor: 'Tutor Data',
    cuidar_alguien: 'Add dependant patient',
    pacientes_tutelados: 'Users associated with your account',
    preanestesia: 'Preanaesthesia',
    autorizado: 'Autorizado',
    no_autorizado: 'No Autorizado',
    peso: 'Weight',
    aceptar_cambios_apto: 'Potwierdź, że chcesz potwierdzić zmianę, aby poprawić?',
    aceptar_cambios_noapto: 'Potwierdź, że chcesz potwierdzić zmianę jako niepoprawną?',
    revision_medica: 'Medical check | medical checkups',
    sin_common: 'The patient does not have {name}',
    aceptar_cambios_rechazo: 'Do you confirm that you want to accept the rejection?',
    aceptar_cambios_denegado: 'Do you confirm that you want to deny the rejection?',
    new_revision: 'New Revision',
    pendiente: 'Pending',
    aceptado: 'Accepted',
    edit_date_revision: 'Edit medical check date',
    fecha_ultimo_aviso: 'Date last notice',
    register_date: 'Registration date',
    no_observations: 'No observations',
    allergy_management: 'Allergy Management',
    add_allergy: 'Add Allergy',
    no_allergies: 'The patient has no registered allergies',
    addedAllergy: 'Allergy saved',
    removedAllergy: 'Allergy removed',
    searchAllergy: 'Search allergies',
    allergyEndDate: 'End date is not defined',
    allergyStartDate: 'Start date is not defined',
    allergyInfoBox: 'This patient has been diagnosed with the following allergies:',
  },
  prescriptions: {
    prescription: 'Prescription',
    prescriptions_list: 'Electronic Prescriptions',
    no_prescriptions: 'You do not have prescriptions',
    not_found: 'No prescriptions found with these search criteria',
    mark_all: 'Check all',
    lote_actions: 'Acciones en lotes',
    action_firm_emit: 'Sign and emit',
    medicine_duration_exceeded_error: 'The duration of this medicine cannot exceed {num} months.',
    narcotic_number_of_packages_exceeded_error:
      'This medicine cannot be prescribed in a quantity exceeding {num} packages.',
    cant_mix_narcotics_with_others_error:
      'A narcotic cannot be prescribed together with other medications in the same prescription.',
    some_medicine_exceeds_max_duration_error:
      'One or more medicines exceed the maximum allowed duration. Please review the data.',
    long_duration_prescription_confirmation_notice:
      'The duration of at least one of the prescribed medicines is greater than one month. Are you sure you want to continue?',
    maxPackagesWarning:
      'At the moment, you can only prescribe up to {limit} units of this medicine. The treatment duration has been automatically readjusted to the maximum number of days possible.',
    maxPackagesWarningNoAdjust: 'At the moment, you can only prescribe up to {limit}.',
    maxPackagesErrorNoCovid: 'The maximum units exceed the 99 units limit',
    maxPackagesErrorCovid: 'The maximun units exceed the 9 units limit',
    medicineAlreadyPrescribed: 'You have an ongoing prescription for this medication that is valid until the {date}',
    limited: 'Maximum {num} {measure}',
    maxDaysForSameMedicineTitle: 'You have exceeded the allowed prescription period.',
    maxDaysForSameMedicineText:
      'This prescription will be removed. The prescriptions for medicines and health products should not exceed 12 months.',
    patientIndentityConfirmationText: 'Confirm the patient identity',
    patientsIndentityConfirmationText: 'Confirm the selected patients identities',
    medicamentsIncluded: 'Includes {numMedicaments} medicaments',
    itemsSelected: ' items selected',
    noActiveMedicines:
      'Ta recepta zawiera co najmniej jeden lek, który nie jest już aktywny. Proszę o wystawienie nowej recepty.',
    deletePrescriptions: 'Delete prescriptions',
  },
  anestesia: {
    de_que_opera: 'What will be your surgical procedure?',
    quien_opera: 'Who is your surgeon?',
    edad: 'Age',
    peso: 'Weight',
    talla: 'Size',
    alergico_medicacion: 'Are you allergic to any medicine?',
    indique_a_que: 'If yes, to what?',
    tension_alta: 'Do you have high blood pressure?',
    diabetico: 'Are you diabetic?',
    fumador: 'Do you smoke?',
    enfemedad_actual: 'Do you suffer any disease?',
    enfermedad_previa: 'Have you suffer from any disease in the past?',
    medicacion: 'Are you taking any medication?',
    operado_anterior: 'Have you had any surgeries in the past?',
    problema_anestesia: 'Have you had any issues with anaesthesia?',
    indique_si_ha_sufrido: 'LET US KNOW IF YOU HAVE HAD ANY OF THE FOLLOWING CONDITIONS:',
    corazon: 'Heart Conditions (Angina, strokes, etc.)',
    pulmon: 'Lung Conditions (bronchitis, asthma, etc.)',
    higado: 'Liver Conditions',
    enfermedad_rinon: 'Kidney Conditions',
    comentarios: 'Any further information that you would like to share with us:',
    fecha_intevencion: 'Scheduled date for surgery',
    informe_preanestesia: 'Preanaesthesia Report',
    checking_collegiate_number_1: "We're verifying your medical ID registration number.",
    checking_collegiate_number_2: 'Please do not close this window.',
  },
  recepcionista: {
    recepcionistas: 'Assistants',
    recepcionista: 'Assistant',
    crear: 'Create assistant',
    editar: 'Edit assistant',
    sin_recepcionista: 'You have not yet registered a assistant',
    permisos: 'Permissions',
    agenda: 'Schedule',
    servicios: 'Services',
    historia: 'History',
    talonario: 'Prescription Pad',
    firma: 'Signature',
    facturacion: 'Billing',
    eliminar_titulo: 'Delete assistant',
    eliminar_texto: 'Are you sure you want to delete this assistant user account?',
  },
  recetas: {
    nueva: 'New Prescription',
    num_receta: 'No. of Prescription',
    ver: 'Open',
    reemitir: 'Reissue',
    prescripcion: 'recepty',
    prescripcion_detalle:
      'Consign the medicine: INN or brand, pharmaceutical form, route of administration, dose per unit and units per container. (Corresponding data in case of medical device).',
    num_envases: 'No. packaging / units',
    buscar_medicamento: 'Search medication',
    receta_medica_privada: 'PRIVATE MEDICAL PRESCRIPCION',
    receta_medica_asistencia_sanitaria_privada: 'MEDICAL PRESCRIPCION FOR PRIVATE HEALTH ASSISTANCE',
    no_existe_medicamento: 'There is no medication with the indicated name',
    unidades: 'Units',
    duracion: 'czas trwania',
    posologia: 'Posology',
    pauta: 'Norm',
    fecha_prevista_dispensacion: 'Expected Date Dispensation',
    info_farmaceutico: 'Information to the Pharmacist, if applicable',
    instrucciones_paciente: 'INSTRUCTIONS TO THE PATIENT (if applicable)',
    paciente_detalle: '(First name, last name, year of birth and ID number / NIE / passport)',
    prescriptor: 'PRESCRIBING',
    prescriptor_detalle: '(identification and signature)',
    vista_previa: 'PREVIEW',
    required: {
      medicamento: 'The name medication is required',
      paciente: 'The name patient is required',
    },
    generar: 'Generate Prescription',
    creada_correctamente: 'Prescription Sent',
    imprimir: 'Print',
    imprimida_correctamente:
      'The Prescription will open in a new tab. If not opened, allow the browser to open pop-up windows',
    nombreaniodni: 'Name, surname, year of birth and ID number',
    aviso_pocas_recetas: 'NOTICE: Only {num} prescription are available',
    logout_with_pending_to_emit:
      'You have {count} pending prescriptions to emit. Are you sure you want to leave the page or emit the prescriptions?',
    emit_prescriptions: 'Emit prescriptions',
    exit_without_emit: 'Close',
    prescription_batch_ended: 'All prescriptions have been signed and emitted successfully.',
    prescription_failed: 'An error occurred while attempting to emit the prescriptions.  Please, try again.',
  },
  success: {
    new_patient: 'konto pacjenta zostało poprawnie utworzone ',
    patient_exist: 'The patient is already registered',
    patient_exist_otro_mail: 'The patient is already registered with another email',
    new_user: 'konto użytkownika zostało poprawnie utworzone',
    edit_patient: 'informacja o pacjencie została poprawnie edytowana',
    edit_user: 'informacja o użytkowniku została poprawnie edytowana',
    save_new_diagnosis: 'nowa diagnoza została poprawnie utworzona',
    save_edit_diagnosis: 'diagnoza została poprawnie edytowana',
    delete_diagnosis: 'diagnoza została poprawnie usunięta',
    save_new_evolucion: 'ewolucja została poprawnie utworzona ',
    save_edit_evolucion: 'ewolucja została poprawnie  edytowana',
    delete_evolucion: 'ewolucja zostałapoprawnieusunięta ',
    save_history: 'historia medyczna została poprawnie zapisana',
    save_user: 'użytkownik zostal zapisany poprawnie',
    save_new_coupon: 'nowy kupon został poprawnie utworzony',
    save_edit_coupon: 'kupon został poprawnie edytowany',
    delete_coupon: 'kupon został poprawnie usuniety',
    save_tipovideo: 'usługa {name} została poprawnie zapisana',
    save_tipomensajeria: 'usługa przesyłania wiadomości została poprawnie zapisana',
    delete_common: 'poprawnie usunięto',
    save_common: 'poprawnie zapisano',
    save_agenda: 'plan został poprawnie zapisany',
    save_firma: 'podpis został poprawnie zapisany',
    send_password: 'Wysłano wiadomość e-mail w celu zresetowania hasła',
    password_changed: 'Hasło zostało poprawnie zmienione',
    generado_informe: 'Raport został poprawnie utworzony',
    account_active: 'konto zostalo poprawnie aktywowane',
    name_file_modificado: 'nazwa pliku została poprawnie zmodyfikowana',
    file_deleted: 'plik został poprawnie usunięty',
    delete_treatment: 'The treatment has been successfully removed',
    recipe_emmited: 'The prescription has been issued correctly, the patient will receive the prescription {methods}',
    emit_method_email: 'via email',
    emit_method_sms: 'via SMS',
    email_sent: 'Email has been sent successfully',
    serviceHiring: 'The {Name} service has been successfully contracted',
  },
  talonario: {
    conf_talonario: 'Prescription Book Settings',
    subir_talonario: 'Upload Book',
    eliminar_talonario: 'Delete Book',
    confirmar_eliminar_talonario: 'Are you sure you want to delete this book?',
    confirmar_subida_talonario:
      ' Are you sure you want to upload the book {msg} to use when you give your patients prescriptions?',
    talonario_actuales: 'Current Books',
    instrucciones_talonario: 'Follow these steps to upload your prescription book:',
    instrucc_talon_li:
      'Step 1: Save in your computer the pdf file you have received by your Medical College. | Step 2: Click on "Upload Book" and choose the correct pdf file. | Step 3: Save the file in your account.',
    sin_talonarios:
      ' You have not uploaded any prescription book yet. You will not be able to generate prescriptions until you upload the pdf file.',
    sin_firma: 'You have not added your signature. Please do so before generating prescriptions.',
    conf_firma: 'Set up your signature',
    firma_actual: 'Current Signature',
    explicacion_firma:
      'Draw your signature from your smartphone or tablet. (It is not recommended to draw it from the computer). It will automatically save in your account and you will be able to access it from any device.',
    mandame_link: 'Send a link to your {nombre}.',
    rehacer_firma: 'Redraw Signature',
    timeoutfirma:
      'The link to draw your signature has expired. Remember it is valid for 15 min since you ask to send it.',
    dibuje_firma:
      "Add your signature from your mobile or tablet. It saves in your profile under the password field. You could access your signature from any device. *It's not recommended use the PC to add your signature.",
    send_signature:
      'We have sent a link to your email account. It contains a link that will let you create your signature (valid for 15 min).',
  },
  tipovideo: {
    estandar: 'standardowa wideokonsultacja',
    precio: 'cena wideokonsultacji',
    duracion: 'czas wideokonsultacji',
    guardar: 'zapisz wideokonsultację',
    guardarmensajeria: 'zapisz wiadomości',
    confirm_desactivar_mensajeria: 'Czy na pewno chcesz dezaktywować usługę przesyłania wiadomości?',
    confirm_desactivar: 'Czy na pewno chcesz dezaktywować usługę wideokonsultacji?',
    nuevo_titulo: 'Utwórz usługę wideokonsultacji',
    nuevo_video: 'Create video consultation Service',
    nuevo_presencial: 'Create face-to-face Service',
    confirm_delete_titulo: 'Usuń usługę wideokosultacji',
    confirm_delete_texto: 'Czy na pewno chcesz usunąć usługę wideokonsultacji?',
  },
  tour: {
    skip: 'Skip tour',
    prev: 'Previous',
    next: 'Next',
    finish: 'Finish',
    dibuja_firma: 'Pruebe a dibujar su firma',
    no_te_gusta: '¿No te gusta como ha quedado?',
    rehacer_firma: 'Puede pulsar en el botón de rehacer firma para volver a dibujar su firma',
    enviar_firma_mobile:
      'Si se encuenta en el ordenador y desea dibujar su firma con el dedo desde {nombre}, pulse sobre este botón.',
    configura_agenda: 'Seleccione los dias que se encuentra disponible en {name}.',
    configura_hora_agenda: 'Una vez seleccionado el día indique la hora de inicio y la hora de fin.',
    configura_rango_agenda: 'Puede seleccionar más de un rango de horas.',
    descripcion_mensajeria: 'Indique la descripción del servicio de mensajería',
    precio_mensajeria: 'Puede modificar el precio de la consulta',
    estado_mensajeria: 'Si no desea recibir consultas puede cambiar el estado a desactivado',
    guardar_cambios: 'Pulse sobre guardar para aplicar los cambios',
  },
  user: {
    name: 'nazwa użytkownika',
    telefono_secretaria: 'telefon służbowy',
    telefono_paciente: "Patient's phone",
    biografia: 'życiorys',
    nuevo: 'nowy użytkownik',
    buscar: 'szukaj użytkownika',
    name_complete: 'imię i naziwsko',
    num_colegiado: 'Numer uprawnień do wykonywania zawodu',
    telefono_consulta: 'numer telefonu  do konsultacji',
    tipo_prof: 'rodzaj specjalisty',
    salutation: 'Salutation',
    password_repeat: 'powtórz hasło',
    password_no_igual: 'hasła nie pasują',
    password_tam_invalid: 'hasło musi mieć co najmniej 6 znaków',
    password_must_contain_lowercase: 'The password must contain lowercase',
    password_must_contain_uppercase: 'The password must contain uppercase',
    password_must_contain_numbers: 'The password must contain numbers',
    password_must_contain_symbols: 'The password must contain special characters',
    invalid_password: 'The password is not valid',
    password_min_length: 'The password must be at least {num} characters',
    user_status: 'User status',
    edit: 'zapisz zmiany',
    search: 'szukaj użytkownika',
    especialidades: 'Specjalności',
    send_account_activation_email: 'Send account activation email',
    csv_with_error: 'There is an error in the document, correct it and upload it again',
    csv_ok: 'All users have been created successfully',
    user_status_training_not_reserved: 'Training not reserved',
    user_status_in_workflow_hubspot: 'In Workflow Hubspot',
    user_status_training_reserved: 'Training reserved',
    user_status_training_made: 'Training made',
    user_status_subscription_paid: 'Subscription paid',
    user_status_completed_personal_data: 'Completed personal data',
    user_status_abandoned_lead: 'Abandoned lead',
    date_end_trial: 'End date of the trial period',
    public_on_marketplace: 'Published at the marketplace',
    no_public_on_marketplace: 'Not published at the marketplace',
    select_image: 'Select an image',
    name_patients_will_see: 'Name patients will see',
  },
  videoconsulta: {
    nueva_video: 'nowa wideokonsultacja',
    nueva_cita: 'Nowa konsultacje',
    crear_cita: 'Utwórz konsultacje',
    nueva_tele: 'New Teleconsultation',
    crear_video: 'utwórz wideokonsultację',
    crear_tele: 'utwórz wideokonsultację',
    pendientes: 'trwająca',
    terminadas: 'zakończona',
    informe: 'Raport',
    sin_video_pendientes: 'nie masz żadnych trwających konsultacji',
    sin_video_terminadas: 'nie uczestniczyles jeszcze w żadnej {name}',
    crear_para_paciente: 'utwórz {tipoVideo} dla pacjenta',
    crear_muticonferencia: 'Create a Multicall',
    como_desea_comunicar: 'jak chcesz poinformować o spotkaniu?',
    solicitando_pago: 'Request Advance Payment',
    solicitando_pago_texto:
      'Wyślij prośbę o płatność pocztą elektroniczną. Po dokonaniu płatności przez pacjenta możesz wysłać pacjentowi zaproszenie do wideokonsultacji',
    cita_cortesia: 'Free',
    cita_cortesia_texto:
      'Te spotkania są bezpłatne dla pacjenta. Umawianie spotkania z tą opcją może być odpowiednie w przypadkach takich jak:',
    cita_cortesia_li:
      'powtórzenie wideokonsultacji, które nie mogły być zakończone/ wyślij zapytanie o pierwszą konsultację lub  o coś innego/ Pacjent , który ma wcześniejszy pakiet wideokonsultacji',
    add_new: 'Add new',
    add_destinatario: 'wpisz osobę na wideokonsultację',
    add_destinatario_cita: 'Wybierz pacjenta',
    add_fechahora: 'wpisz  godzinę i datę wideokonsultacji',
    add_fechahora_cita: 'Podaj datę i godzinę spotkania',
    add_modocomunicacion: 'wpisz sposób w jaki chcesz poinformować pacjenta o wideokonsultacji',
    add_tiposervicio: 'wpisz rodzaj wideokonsultacji',
    add_tiposervicio_cita: 'wpisz rodzaj konsultacji',
    solapamiento: 'w tym czasie jest już zaplanowana wideokonsultacja',
    sin_tiposvideo: 'aby utworzyć wideokonsultację musisz mieć aktywną co najmniej jedną usługę',
    enviado_correctamente: 'Wideokonsultacja została poprawnie utworzona. Wysłano email z informacją dla pacjenta',
    enviado_correctamente_sms: 'Wideokonsultacja została poprawnie utworzona. Wysłano SMS z informacją dla pacjenta',
    enviado_recordatorio: 'wysłano do pacjenta email przypominający o wideokonsultacji',
    cortesia: 'uprzejmościowo',
    esperando_pago: 'oczekiwanie na zapłatę',
    tipo: 'typ',
    videoconsulta_para: '{tipoVideo} dla',
    codigo: 'kod',
    abrir_sala: 'otwarta grupa',
    volver_enviar: 'wyślij kod ponownie',
    confirm_delete: 'Czy na pewno chcesz odwołać wideokonsultację?',
    confirm_delete2:
      'The video consultation is already paid. If necessary to make make a refund of money to the patient contact us through the chat.',
    confirm_video: 'Czy na pewno chcesz potwierdzić wideokonsultację?',
    cancelado_correctamente: 'Wideokonsultacja została poprawnie odwołana',
    notification_email: 'Do pacjenta zostanie wysłana wiadomość e-mail z potwierdzeniem odwołania wizyty',
    confirmado_correctamente: 'wideokonsultacja zaostała poprawnie potwierdzona',
    terminada: 'wideokonsultacja zakończona',
    singular: 'wideokonsultacja',
    teleconsulta: 'wideokonsultacja',
    sin_confirmar: 'bez potwierdzenia',
    rechazada: 'Rejected',
    aceptada: 'Accepted',
    denegada: 'Refused',
    ok: 'Ok',
    multiconferencia: 'Multicall',
    tipo_cita: 'Type of Video Consultation',
    convocatoria_sms: 'Send the appointment info by {sms}',
    editar_servicio: 'Editar Servicio',
    presencial: 'Face-to-face appointment | Face-to-face appointments',
    sms: 'SMS',
    mensaje_texto: 'Mensaje de texto',
    diagnostico: 'Diagnoza',
    tratamiento: 'Leczenie',
    freemium_version_non_free:
      'Con la versión Freemium no puede crear servicios de cortesía o gratuitos. Contacte con su comercial para actualizar a la versión Premium',
    fixed_fee_message:
      'El fee de gestión de este servicio es de {feeValue} € que se añadirá a su tarifa en el momento del pago por parte del paciente.',
    percentage_fee_message:
      'El fee de gestión de este servicio es del {feeValue}% con un mínimo de {feeMin}€ y un máximo de {feeMax}€ que se añadirá a su tarifa en el momento del pago por parte del paciente.',
    notice: 'Notice',
    no_specialties_to_create:
      'In order to continue you must assign a speciality. Please go to your profile and update it',
  },
  contact: {
    texto: 'Jeśli masz jakiekolwiek wątpliwości, prześlij nam wiadomość za pomocą tego formularza',
    mensaje_obligatorio: 'Kontaktując się z nami, napisz nam o swoich wątpliwościach',
    motivo_obligatorio: 'Kontaktując się z nami, podaj powód swojej wątpliwości',
    enviado_correctamente: 'Dziękujemy  za skontaktowanie się z nami! Odpowiemy tak szybko, jak to możliwe.',
  },
  empresa: {
    nueva: 'New Company',
    buscar: 'Search Company',
    email_noty: 'Notification Email',
    email_admin: 'Admin Email',
    share_patients: 'Share Patients',
    nombre_required: 'Company name is required',
    email_required: 'Admin email is required',
    licenses_num: 'Licenses num.',
    guardar_empresa: 'Save Company',
    edit_empresa_success: 'The company has been successfully edited',
    has_anesthetist: 'Tiene Anestesista',
    share_receptionists: 'Share Assistants',
    without_licenses: 'Your licenses have run out',
    user_exists: 'This user already exists',
    forbidden: "You don't have permissions to modify this company",
    user_not_exists: "This user doesn't exist",
    edit: 'Edit company',
    create: 'Create company',
    delete: 'Delete Company',
    confirm_delete: 'Are you sure you want to delete this company?',
    cannot_delete:
      'It is not possible to delete this company, as there are professionals / assistants associated with it',
    delete_success: 'The company has been successfully removed',
    premium_payment_type: 'Premium payment type',
    premium_payment_types: 'Premium payment types',
    is_collective: 'Is collective',
    is_health_personnel: 'Is health personnel',
    have_interconsultation: 'Have interconsultation',
    interconsultation: 'Interconsultation',
    users: 'Users',
    public_email: 'Public email',
    public_phone: 'Public phone number',
    public_address: 'Public address'
  },
  landing: {
    titulo: 'Setting up my Website',
    direccion: 'Address',
    sections: 'Sections',
    add_seccion: 'Add Section',
    add_seccion_titulo: 'Add Section to My Website',
    seccion_anadida: 'Section added correctly',
    delete_seccion_titulo: 'Delete Section',
    delete_seccion_texto: 'Are you sure you want to delete this section of your website?',
    seccion_eliminada: 'Section deleted correctly',
    publicar: 'Publish',
    modificar_precios: 'Modify Prices',
    redes_sociales: 'Sieci Społecznościowe',
    ejemplo_web: 'Przykład strony internetowej',
    en_este_apartado: 'In this section you configure the data that will appear on your website',
    legend:
      'Photo, name, medical specialty and profile data are collected automatically from the data set on your profile.',
    legend_significance: 'Meaning of the fields to fill',
    write_address: 'Enter the address of your company',
    write_web: 'Enter the link of your particular website, if you already have one.',
    write_socials: 'Enter the links of your operational social networks',
    write_sections: 'You can add the sections you want including information',
  },
  suscripciones: {
    suscripcion: 'Subscription',
    suscripciones: 'Subscriptions',
    nueva: 'New Subscription',
    nueva_titulo: 'New Monthly Subscription',
    editar: 'Edit Subscription',
    tarifa_plana: 'Flat Rate',
    a_medida: 'Custom Subscription',
    sin_suscripciones: 'No subscription has been configured',
    destinatario: 'Who can hire it',
    explicacion_destinatario: 'When selecting anyone this service will appear in the marketplace',
    servicio: 'Service',
    ilimitado: 'Unlimited',
    servicios_incluidos: 'Included services',
    sesiones: 'session | sessions',
    error_no_servicios: 'You have not included any service. Press the button (+) to add services',
    delete: 'Remove Subscription',
    confirm_delete: 'Are you sure you want to delete the subscription?',
    suscripcion_contratados: 'My Subscriptions',
    sin_suscripciones_contratadas: 'At this time you do not have any hired subscription',
    mensajeria_restantes: 'Remaining Messaging',
    videoconsulta_restantes: 'Remaining Videoconsults',
    cancelar_suscripcion: 'Cancel Subscription',
    cancelar_suscripcion_texto:
      'The subscription will be canceled from {dia}. Meanwhile, the patient can continue to use this service. Are you sure you want to cancel the subscription?',
    cancelado_correctamente:
      'The subscription has been cancelled successfully. No more charges will be made to the patient.',
  },
  reserva: {
    titulo: 'Book your training appointment',
    texto1:
      'We encourage you to schedule an appointment with our Medical Director to discover all the tools {name} offers.',
    texto2: 'Do yo wish to book the appointment?',
    boton: 'Book',
    reserva_confirmada: 'Appointment Booked.',
    ha_hecho_una_reserva: 'You have scheduled an appointment with Dr. Medina.',
    recibira_invitacion_por_correo: 'You will receive a confirmation by email..',
    se_reservara_a_las: 'You are booking an appointment on {dia} at {hora}',
    seleccione_fecha_hora: 'Choose the date and time you wish to receive the training.',
    reunirse_roberto: 'Meeting with Dr. Roberto Medina',
  },
  producto: {
    nuevo: 'New Product',
    editar: 'Edit Product',
    buscar: 'Search Product',
    nombre_required: 'The name of the product is required.',
    precio_required: 'The product price is required.',
    creado: 'The product has been created correctly.',
    licencia: 'Number of licences',
  },
  pago: {
    titulo: 'Subscription (Secure Payment)',
    titulo_stripe: 'Subscription',
    pagar_suscripcion: 'Purchase Subscription',
    cuenta_activada_correctamente: 'The account has been activated correctly.',
    ciclo: 'Billing cicle: based on your license description',
    periodo_duracion: 'Valid until: undefined',
    explicacion:
      "You will be charged immediately this month's payment and for every following month, a recurring payment will be charged for the same amount until the service is cancelled.",
  },
  steps: {
    espacio: 'My {name} Page',
    datos_facturacion: 'Billing information',
    dni_anverso_subido: 'UPLOADED FRONT DOCUMENT ID.',
    dni_reverso_subido: 'UPLOADED BACK DOCUMENT ID.',
    subir_dni_anverso: 'UPLOAD FRONT DOCUMENT ID.',
    subir_dni_reverso: 'UPLOAD BACK DOCUMENT ID.',
    iban_payment_module_message:
      'It is necessary that you complete the IBAN field, with the bank account data, when you intend to make payments to patients, for any of the appointments or consultations made through this platform, and configure the POS for online collections.',
    iban_invalid: 'IBAN is invalid, enter a valid IBAN',
    cp_invalid: 'Postal code is not correct',
  },
  resenas: {
    titulo_publicar: 'Publicar Reseña',
    texto_publicar: '¿Esta seguro que desea publicar esta reseña?',
    titulo_eliminar: 'Eliminar Reseña',
    texto_eliminar: '¿Esta seguro que desea eliminar esta reseña?',
  },
  dispositivo: {
    new_entidad: 'Nueva Entidad',
    entidad: 'Entidad | Entidades',
    new_local: 'Nuevo Local',
    local: 'Local | Locales',
    new_persona: 'Nueva Persona de Contacto',
    persona_contacto: 'Persona de Contacto | Personas de Contacto',
    persona: 'Persona de Contacto | Personas de Contacto',
    new_dispositivo: 'Nuevo Dispositivo',
    dispositivo: 'Dispositivo | Dispositivos',
    venta: 'Venta',
    alquiler: 'Alquiler',
    titulo_eliminar: 'Eliminar {tipo}',
    texto_eliminar: '¿Esta seguro que desea eliminar esta {tipo}?',
  },
  servicios: {
    servicios: 'Usługi',
    activado: 'Aktywne',
    nombre: 'Nazwa',
    estandar: 'Standard',
    duracion: 'Czas trwania wideokonsultacji',
    min: 'Min. (Minuty)',
    descripcion: 'Opis',
    videoconsulta_defecto: 'Domyślna usługa wideokonsultacji',
    estado_activo: 'Status: Aktywny',
  },
  reportes: {
    informe_pontgrup: 'Pont-Grup activity reports',
    informe_productividad: 'Productivity Report',
    tiempos_atencion_consulta: 'Consultation Attention Times',
    cumplimiento_servicio: 'Service Compliance Control',
    num_altas: 'New Subscriptions',
    num_bajas: 'Cancellations',
    num_renov: 'Renovations',
    total: 'Total',
    seleccione_profesional: 'It is mandatory to select a professional',
    filter_hospital: 'Hospital filter',
    filter_professional: 'Profesional filter',
    filter_from_date: 'Start date',
    filter_to_date: 'End date',
    activation_mails: 'Activation mails sent',
    activated_accounts: 'Activated accounts',
    num_videocons: 'Videoconsulta',
    num_chats: 'Chats',
    num_patients: 'Registered patients',
    num_electronic_presc: 'Electronic Prescriptions emited',
    num_pdf_presc: 'PDF Prescriptions emited',
    num_logins: 'Logins',
    num_registers: 'Num. Regs',
    graph_accounts: 'Active accounts/inactive',
    graph_activity: 'Registered Activity',
    chart_electronic_presc: 'Electronic presc.',
    chart_pdf_presc: 'PDF prescriptions',
    active_accounts: 'Actives',
    inactive_accounts: 'Inactives',
    date_filters: 'Date Filters',
  },
  permissions: {
    dashboard: 'Dashboard',
    conf_permissions: 'Permission Settings',
    conf_styles: 'Styles Settings',
    save_permissions: 'Save Permissions',
    save_styles: 'Guardar Styles',
    users: 'Users',
    companies: 'Companies',
    products: 'Products',
    devices: 'Devices',
    patients: 'Patients',
    videoConsultation: 'Video Consultation',
    billing: 'Billing',
    messaging: 'Chat',
    packsHired: 'Packs',
    subscriptionsHired: 'Subscriptions',
    reviews: 'Reviews',
    configServices: 'Services Settings',
    reports: 'Reports',
    settingsBook: 'Settings - Book',
    settingsBilling: 'Settings - Billing',
    settingsRates: 'Settings - Rates',
    settingsSign: 'Settings - Sign',
    settingsDiary: 'Settings - Diary',
    settingsPacks: 'Settings - Packs',
    settingsCoupons: 'Settings - Coupons',
    settingsLanding: 'Settings - Landing',
    settingsReceptionist: 'Settings - Assistant',
    settingsSpecialties: 'Settings - Specialties',
    settingsServices: 'Settings - Services',
    settingsDaysNotAvailable: 'Settings - Days not Available',
    settingsDaysAvailable: 'Settings - Available Days',
    contact: 'Contact',
    help: 'Help',
    haveCreateVideoWithTel: 'Video Consultation by phone',
    adminSimplified: 'Admin Simplified',
    marketplace: 'Marketplace',
    showIbanPaymentModuleMessage: 'Show IBAN Message',
    simplifiedLanguages: 'Hide Languages (PT/PL/AR)',
    hideStripeButton: 'Hide Stripe Button',
    hideFormBooking: 'Hide Form Booking',
    hideTrialMessage: 'Hide Trial Message',
    clinicalHistory: 'Clinical History',
    anesthesia: 'Pre-anesthesia form',
    documents: 'Documents',
    haveElectronicPrescription: 'E-prescription',
    myMessages: 'Patients - Messages',
    myVideoconsultations: 'Patients - Video Consultations',
    myPrescriptions: 'Patients - Prescriptions',
    mySubscriptions: 'Patients - Subscriptions',
    myDocuments: 'Patients - Documents',
    sendUsMessage: 'Send us a Message',
    beneficiaries: 'Care for someone',
    settingsBeneficiaries: 'Beneficiary adjustments',
    importCsvOnUsersList: 'Import CSV Users',
    showWelcomeModal: 'Show Welcome Popup',
    prescriptionsList: 'Batch prescriptions',
    moduleMedicalTests: 'Module testing',
    personalDoctor: 'Personal doctor',
    personalDoctorPatient: 'Patient',
    personalDoctorProfessional: 'Professional',
    personalDoctorAssistant: 'Assistant',
    hidePatientCreation: 'Hide patient creation',
  },
  recipe: {
    diagnostic: 'Diagnostic',
    chronic: 'Chronic',
    confidential: 'Confidential',
    patient_instructions: 'Patient instructions',
    pharmacist_warnings: 'Indications for the pharmacist',
    comments: 'Comments',
    treatment_created: 'The treatment has been created successfully',
    sending: 'Sending',
    err_empty_diagnostic: 'You must complete the diagnostic field',
    err_empty_datestart: 'You must fill in the start date',
    err_empty_dateend: 'You must fill in the end date',
    err_empty_posology: 'You must fill in the posology',
    err_empty_duration: 'You must fill in the duration',
    err_empty_guideline: 'Debe rellenar la pauta',
    err_empty_tincode: 'You must fill in the guideline',
    err_empty_medicine: 'You must select a medicine',
    err_empty_composition: 'You must fill in the composition',
    unit: 'Unit',
    units: 'Units',
    recipe: 'Prescription',
    print_recipe: 'Print recipe',
    tin_code: 'TIN Code',
    master_formula: 'Master Formula',
    master_formula_placeholder: 'Write the formula here',
    medicine: 'Medicine',
    medicines: 'Medicines',
    search_medicine: 'Type the name of the medication to look for',
    search_patient: 'Search by patient name',
    add_medicines: '+ Add medication',
    type_electronic: 'Electronics',
    type_pdf: 'PDF',
    type: 'Type',
    sel_type: 'Select type',
    create_treatment: 'Create Treatment',
    create_treatment_treat: 'Create Treatment',
    years: 'years',
    change_patient: 'Change patient',
    allergies: 'Allergies',
    num_recipe: 'Num Prescription',
    last_disp: 'Last Disp',
    next_disp: 'Next Disp',
    active: 'Active',
    inactive: 'Inactive',
    confirm_delete_treatment: 'Are you sure you want to delete the treatment?',
    confirm_delete_prescription: 'Are you sure you want to delete the prescription?',
    confirm_emit_prescription: 'Do you want to emmit this prescription?',
    create_prescription: 'Create prescription',
    edit_prescription: 'Edit prescription',
    typology: 'Typology',
    pending: 'Pending',
    partially_dispensed: 'Partially dispensed',
    dispensed: 'Dispensed',
    download: 'Download prescription',
    narcotic: 'Narcotic',
    generic: 'Generic',
    hours: 'hours',
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    month: 'month',
    year: 'years',
    during: 'During',
    every: 'every',
    created_at: 'Created at',
    frequency: 'Frequency',
    new_prescription: 'Create Electronic prescription',
    emmited: 'Issued',
    save_prescription: 'Save prescription',
    confirm_emmit_prescription_text: 'This action will send the prescription to your patient',
    see_prescription: 'See prescription',
    choose_prescription_type: 'Choose type of prescription',
    paper_recipe: 'PDF prescription',
    title: 'E-prescription',
    from: 'from',
    to: 'to',
    created_date: 'Created date',
    filter_by_created_date: 'Filter by creation date',
    more_information: 'More information',
    prescriptions: 'Prescriptions',
    premium_title: 'Unlock Premium content',
    patient_dni_required:
      "The patient must have the ID number filled to issue a prescription, go to the patient's personal data and fill in the ID number",
    reject_prof: 'Reject',
    reject_without_notification: 'Reject without notification',
    reject_with_notification: 'Ask for more information',
    master_formulas: 'Master Formulas',
    add_master_formula: 'Add master formula',
    psychotropic: 'Psychotropic',
    composition: 'Composition',
    pharmaceuticalForm: 'Pharmaceutical Form',
    administrationWay: 'Administration Way',
    dosage: 'Dosage',
    observations: 'Observations',
    err_master_formulas: 'You must complete all the required fields of the master formulas',
    medicine_detail: 'Medicine detail',
    medicine_information: 'Medicine information',
    registration_number: 'Registration number',
    authorized: 'Authorized',
    revoked: 'Revoked',
    discontinued: 'Discontinued',
    package_leaflet: 'Package leaflet',
    technical_sheet: 'Technical sheet',
    active_ingredient: 'Active ingredient',
    renew_prescription: 'Renew prescription',
    cancel_prescription: 'Cancel prescription',
    select_reason: 'Choose the reason for cancellation',
    cancel_reason_0: 'Precautionary lock confirmed by term',
    cancel_reason_1: 'Cancellation due to end of treatment',
    cancel_reason_2: 'Treatment change',
    cancel_reason_3: 'Incomplete or wrong data',
    canceled: 'Canceled',
    canceled_message: 'Prescription canceled',
    anulate_medicament: 'Cancel',
    anulate_indications: 'Anulate reasons',
    anulate_message: 'Medicament is anulated',
    unlock_medicament: 'Unlock',
    unlock_indications: 'Unlock reasons',
    unlock_message: 'Medicament unlocked',
    emitted: 'Emitted',
    expired: 'Expired',
    finished: 'Finished',
    in_queue: 'In proccess to emit',
    units_dispensed: 'Box or doses dispatched',
    navigate_to_prescription: 'Go to Prescription',
  },
  medicine: {
    filter_by_fav: 'Filter by favorites',
  },
  service: {
    name: 'Service of {name}',
    more_services: 'More Services',
  },
  signup: {
    dont_have_account_yet: "Don't have an account yet? Sign up",
    title_patient: 'Sign up as a Patient',
    subtitle_patient: 'Join {name} and get a consultation with health professionals from everywhere',
    title_professional: 'Sign up as Healthcare Professional',
    subtitle_professional: 'Join {name} and connect with your patients from everywhere',
    i_am_patient: 'I am Patient',
    i_am_professional: 'I am Healthcare Professional',
    start_enjoying: 'Start enjoying the benefits of using the platform {name}',
    signup_successfully: 'You have successfully registered',
    check_your_inbox: 'Please check your email account to activate your account',
    select_professional: 'Select a professional ...',
    select_center: 'Select the center you belong to...',
  },
  subscriptions: {
    name: 'Subscription | Subscriptions',
    not_have_hired: 'You have no subscription contracted',
    renewal: 'Renewal',
    expire: 'Expire',
    request_new_service: 'Request new service',
    confirm_cancel_explain:
      'This service will not be available from {date} Are you sure you want to cancel your subscription?',
    cancelled_ok: 'Subscription has been successfully canceled',
  },
  videoconsultations: {
    confirmed: 'Confirmed',
    pending_confirm: 'Pending confirmation',
    pending_payment: 'Payment pending',
    go_to_video: 'Enter video consultation',
    pending_confirm_explain: 'The professional must confirm the video consultation',
    pay_video: 'Pay video consultation',
    pay: 'Pay',
    to_access: 'Access',
    expire_at: 'Expire at',
    download_recording: 'Download recording',
    show_video: 'Show video consultation',
    seconds: 'Seconds',
    paymentPatientNotCorresponding:
      'We have detected that there is a session started that does not correspond to that of the patient to whom the video consultation is directed. To pay, please log out and try again.',
  },
  session: {
    title: 'Session Timeout',
    subtitle: 'Your online session will expire in',
    advice: 'Please click "Continue" to keep working or click "Log Off" to end your session now',
    close_session: 'Log Off',
    continue: 'Continue',
    second: 'second | seconds',
  },
  webphone: {
    calling: 'Calling',
    mute: 'Mute',
    pause: 'Pause',
    speaker: 'Speaker',
    hang_up: 'Hang Up',
    call: 'Call',
    new_call: 'Phone Call',
    please_enter_number: 'Please enter a phone number',
    please_enter_valid_number: 'Please enter a valid phone number',
    please_enter_valid_es_number: 'Please enter a valid spanish phone number',
    an_error_ocurred: 'An error ocurred!',
    retry: 'Retry',
    token_expired: 'Token expired',
    webphone: 'Webphone',
  },
  incompatibleBrowser: {
    title: 'Unsupported browser',
    message: 'You are using a web browser that is not supported by Docline.',
    title2: '',
    message2: '',
    helpCenterMessage: 'If you want to know which are our compatible browsers',
    helpCenterLinkName: 'visit our help article.',
    helpCenterLink: 'https://help.docline.es/article/75-supportedbrowsers',
  },
  triage: {
    start: 'Start triage',
    finish: 'Finish triage',
    title: 'Triage',
    code: 'Triage code',
    generate_code: 'Generate a new code',
    explain: 'This code allows the patient to start the triage on their own.',
    code_error: 'Triage code invalid!',
    type_code: 'Introduce the triage code to start.',
  },
  registro: {
    professional_registration_process: 'Health professional registration process',
    init_session: 'Log in',
    have_an_account: 'Already have an account?',
    personal_info: 'Personal information',
    please_complete_info: 'Please complete your professional information.',
    documents: 'Documents',
    add_passport: 'Please attach your passport.',
    add_nie: 'Please attach your NIE on both sides.',
    add_dni: 'Please attach your identity card on both sides.',
    front_image: 'Image front side',
    rear_image: 'Image rear side',
    accept_conditions:
      'I have read and accept the <a href="{privacyPolicyLink}" target="_blank">terms of use and privacy policy</a>',
    drag_or_select_file: 'Drag the image here or <span class="filepond--label-action open">select the file</span>.',
    save_ok_title: 'Successfully saved',
    save_ok_text:
      'You have successfully registered, you will receive an email when we validate your details and you can start using our service.',
    alert_front_image: 'You must enter the image of the front side of the document.',
    alert_rear_image: 'You must enter an image of the back side of the document',
    alert_accept_conditions: 'You must accept the terms of use and privacy policy.',
    alert_image_max_size: 'The image must not exceed 2MB',
    alert_image_format: 'The format of the image must be .png o .jpg',
    alert_input_required: 'Mandatory field',
    alert_phone_required: 'Telephone is mandatory',
    alert_phone_is_invalid: 'The phone is invalid',
    alert_email_required: 'E-mail is mandatory',
    alert_email_is_invalid: 'E-mail must be valid',
    alert_number_required: 'The license number is mandatory',
    alert_number_is_invalid: 'The license number is not valid',
  },
  verifyusers: {
    to_be_verified: 'To be verified',
    verified: 'Verified',
    verified_pendings: 'Pending verification',
    reject: 'Reject',
    loading: 'Loading',
  },
  medicalTests: {
    analytical_tests_list: 'Analytical tests list',
    analytical_tests_list_types: 'Types of analytical tests',
    created_at: 'Created at',
    code: 'Code',
    description: 'Description',
    new_test: 'New test',
    delete_test: 'Delete analytical test.',
    confirm_delete_test: 'Are you sure you want to delete this analytical test?',
    new_type_of_test: 'New type of test',
    test_type_code: 'Test type code',
    tests_included: 'Tests included',
    observations: 'Observations',
    invalidCodeFormat: 'Invalid code format',
    almostOneAct: 'Must add at least one act',
    description_too_short: 'Description too short',
    create_test_success: 'Type test create success',
    new_analytical_test: 'New test',
    no_data: 'No data available',
    nomenclator: 'Nomenclature',
    new_act: 'New act',
    new_nomenclator: 'New nomenclature entry',
    edit_nomenclator: 'Editar nomenclature entry',
    specialty: 'Specialty',
    group: 'Group',
    collection: 'Collection',
    subgroup: 'Subgroup',
    codeCiepq: 'CIEP-9 Code',
    invalidCiep9: 'The format must be a 4-digit numeric (XXXX)',
    codeCie9: 'CIE-9 Code',
    addAnotherCode: 'Add another code',
    codesCie9: 'CIE-9 codes',
    externalId: 'External ID',
    actDescription: 'Act description',
    search: 'Search',
    request_medical_test: 'Request medical test',
    request_analytical_test: 'Request analytical test',
    patient_info: 'Patient Information',
    select_medical_test: 'Select medical test',
    select_medical_test_type: 'Select medical test type',
    analytical_test: 'Analytical test',
    other_medical_tests: 'Other medical tests',
    medical_tests_requested: 'Requested medical tests',
    other_medical_tests_requested: 'Other requested medical tests',
    medical_test_description: 'Medical test description',
    medical_test_recommendations: 'Medical test recommendations',
    medical_test_request: 'Request',
    add_act: 'Add act',
    delete_act: 'Delete act',
    select_acts: 'Select acts',
    confirm_delete_act: 'Are you sure you want to delete the act?',
    select_some_test: 'Select a test',
    select_patient: 'Select a patient',
    request_medical_test_success: 'Request generated successfully',

    request_rx_test: 'Request rx test',
    rx_test: 'RX test',
    rx_structured_view: 'Structured view',
    rx_simple_view: 'Simple view',
    rx_modality: 'Modality',
    rx_anatomical_region: 'Anatomical region',
    rx_exploration: 'Exploration',
    rx_search_hint: 'Search RX tests',
    rx_not_found: 'We have not found tests with that description',
    rx_protocols: 'RX protocols',
    new_rx_protocol: 'New RX protocol',
    request_other_test: 'Request other tests',
    other_test: 'Other test',
  },
  personal_doctor: {
    personal_doctor: 'Personal doctor',
    personal_doctors_historical: 'Historical personal doctors',
    personal_doctor_present: 'Present',
    loading_personal_doctors: 'Loading professionals list...',
    not_found_personal_doctors: 'We have not found professionals with this name.',
    network_error: 'There was a problem loading the list of professionals.',
    network_error_short: 'There was a problem loading the list',
    retry: 'Retry',
    pending_assignment: 'Assignment pending',
    is_your_personal_doctor: 'I am your personal doctor',
  },
};
