import {getHeader, professionalLanguages, sickLeaveManagement} from "../../config/config";
import axios from "axios";

export const saveLanguages = async (professionalId, languages) => {
  const url = new URL(professionalLanguages(professionalId));

  const params = {
    languages: languages,
  };

  const raw = await axios.put(url.toString(), params,{
    headers: getHeader(),
  });
  return raw.data;
};

export const retrieveLanguages = async (professionalId) => {
  const url = new URL(professionalLanguages(professionalId));

  const raw = await axios.get(url.toString(),{
    headers: getHeader(),
  });

  return raw.data.languages;
};
